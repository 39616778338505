import styled from 'styled-components';

const entryVAT = `
  width: 6em;
  padding: 0;
`;

export const EntryVatTh = styled.th`
  ${entryVAT}
`;

export const EntryVatTd = styled.td`
  padding: 0;
`;
