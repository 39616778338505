import { getTotalVat } from 'components/src/helpers/getTotalVat';
import * as React from 'react';
import { TemplatePublicInvoice } from '../common';
import { DocumentSummary } from './../../components/DocumentSummary';
import {
  Col,
  Container,
  ContractSide,
  ContractSides,
  DateWithLabel,
  DocumentNumber,
  EntryGrossTh,
  EntryNetTh,
  EntryVatTotalTh,
  Header,
  Heading1,
  IssuerIdentity,
  IssuerSignature,
  Label,
  NameSurname,
  Payment,
  SubHeader,
  Table,
} from './../../components/DocumentTemplate-styles';
import { PaymentDetail } from './../../components/PaymentDetail';
import { PrintCurrency } from './../../components/PrintCurrency';
import { EntryNameTh } from './../../components/Table/EntryName';
import { EntryNumberTh } from './../../components/Table/EntryNumber';
import { EntryQuantityTh } from './../../components/Table/EntryQuantity';
import { EntryRow } from './../../components/Table/EntryRow';
import { EntryVatTh } from './../../components/Table/EntryVat';
import { Summary } from './../../components/Table/Summary';
import { TotalToPayDetail } from './../../components/TotalToPayDetail';
import { SPLIT_PAYMENT_LABEL } from './consts';
import { getPublicInvoiceTitle } from './helpers/getInvoiceTemplateTitle';
import { InvoiceAnnotation } from './InvoiceAnnotation';

const get = require('lodash/get');

export type PublicInvoiceDocumentProps = {
  invoice: TemplatePublicInvoice;
  defaultCurrencyCode: string;

  formatDate(value: Date): string;
};
export const PublicInvoiceDocument = (props: PublicInvoiceDocumentProps): JSX.Element => {
  const { invoice, defaultCurrencyCode, formatDate } = props;

  const issuedAt = formatDate(invoice.issuedAt);
  const paymentDate = formatDate(invoice.paymentDate);
  const currencyCode = defaultCurrencyCode;
  const sellDate = invoice.sellDate;

  return (
    <Container>
      <Header>
        <Col className="preventExpanding">
          <Heading1>
            <span>{getPublicInvoiceTitle()}</span>
            <br/>
            <DocumentNumber>{invoice.number}</DocumentNumber>
          </Heading1>
          <DateWithLabel>
            <Label>Data&nbsp;wystawienia</Label>&nbsp;<span>{issuedAt}</span>
          </DateWithLabel>
          {sellDate && (
            <DateWithLabel>
              <Label>Data&nbsp;sprzedaży</Label>&nbsp;<span>{formatDate(sellDate)}</span>
            </DateWithLabel>
          )}
          {invoice.cashAccounting && (
            <InvoiceAnnotation>
              Metoda kasowa
            </InvoiceAnnotation>
          )}
        </Col>
      </Header>
      <ContractSides>
        <Col>
          <ContractSide>
            <SubHeader>Sprzedawca</SubHeader>
            <span style={{ whiteSpace: 'pre' }}>
              {invoice.seller}
            </span>
          </ContractSide>
        </Col>
        <Col>
          <ContractSide>
            <SubHeader>Nabywca</SubHeader>
            <span style={{ whiteSpace: 'pre' }}>
              {invoice.buyer}
            </span>
          </ContractSide>
        </Col>
      </ContractSides>
      <>
        <SubHeader>Transakcja</SubHeader>
        <Table>
          <table>
            <thead>
            <tr>
              <EntryNumberTh>
                Lp
              </EntryNumberTh>
              <EntryNameTh>
                Nazwa towaru / usługi
              </EntryNameTh>
              <EntryQuantityTh>
                Ilość
              </EntryQuantityTh>
              <EntryNetTh>
                Wartość jedn.
              </EntryNetTh>
              <EntryNetTh>
                Wartość
              </EntryNetTh>
              <EntryVatTh>VAT %</EntryVatTh>
              <EntryVatTotalTh>
                Wartość VAT
              </EntryVatTotalTh>
              <EntryGrossTh>
                Wartość brutto
              </EntryGrossTh>
            </tr>
            </thead>
            <tbody>
            {invoice.entries.map((entry, idx) =>
              <EntryRow
                key={idx}
                entry={entry}
                index={idx}
                hideVAT={false}
                totalVat={getTotalVat({
                  changeRate: get(invoice, 'currencyDetails.rate.mid', null),
                  totalNet: entry.totalNet,
                  totalGross: entry.totalGross,
                })}
              />,
            )}
            </tbody>
            <tfoot>
            <Summary
              summary={invoice.summary}
            />
            </tfoot>
          </table>
        </Table>
      </>
      <DocumentSummary>
        <Payment>
          <PaymentDetail title="Termin płatności">
            {paymentDate}
          </PaymentDetail>
          {invoice.paymentData && (
            <PaymentDetail title="Nr rachunku bankowego">
              <span>{invoice.paymentData}</span>
            </PaymentDetail>
          )}
          {invoice.splitPayment && (
            <PaymentDetail title="">
              <span style={{ fontWeight: 'bold' }}>{SPLIT_PAYMENT_LABEL}</span>
            </PaymentDetail>
          )}
          <TotalToPayDetail
            sumGross={invoice.summary.sumGross}
            currencyCode={currencyCode}
          />
          {(invoice.payment && !isNaN(invoice.payment))
            ? (
              <PaymentDetail title="Zapłacono">
                <PrintCurrency
                  numericValue={invoice.payment}
                />&nbsp;{currencyCode}
              </PaymentDetail>
            ) : null
          }
          <PaymentDetail title="Pozostało do zapłaty">
            <PrintCurrency
              numericValue={invoice.toPay || 0}
            />&nbsp;{currencyCode}
          </PaymentDetail>
          {invoice.paymentMethodDisplayName && invoice.paymentMethodDisplayName.length > 0 && (
            <PaymentDetail title="Metoda płatności">
              {invoice.paymentMethodDisplayName}
            </PaymentDetail>
          )}
          {invoice.taxExemptionLegalBasis && invoice.taxExemptionLegalBasis !== '' && (
            <PaymentDetail title="Podstawa prawna zwolnienia z podatku VAT">
              {invoice.taxExemptionLegalBasis}
            </PaymentDetail>
          )}
        </Payment>
      </DocumentSummary>
      {Boolean(invoice.comments) && (
        <PaymentDetail title="Komentarz">
          {invoice.comments}
        </PaymentDetail>
      )}
      <IssuerSignature>
        <Col>
          <IssuerIdentity>
            <Label>Imię i nazwisko wystawcy</Label><br/>
            <NameSurname>{invoice.issuer}</NameSurname>
          </IssuerIdentity>
        </Col>
        <Col>
          <IssuerIdentity>
            <Label>Imię i nazwisko odbiorcy</Label><br/>
            <NameSurname>{invoice.receiver}</NameSurname>
          </IssuerIdentity>
        </Col>
      </IssuerSignature>
    </Container>
  );
};
