import * as React from 'react';
import styled from 'styled-components';

const PaymentDetailDiv = styled.div`
  display: flex;

  h3 {
    display: inline-block;
    width: 12em;
    min-width: 12em;
    margin: 0.31em 1em 0.31em 0;
    font-size: 1em;
  }

  span {
    word-break: break-word;
    margin-top: 0.3em;
  }
`;

type Props = {
  title?: React.ReactNode;
  children: React.ReactNode;
  bold?: boolean;
};

const renderTitle = (title?: React.ReactNode): React.ReactNode => title
  ? <>{title}:</>
  : <>&nbsp;</>;

export const PaymentDetail: React.FC<Props> = ({ title, children, bold = false }) => (
  <PaymentDetailDiv style={bold ? { fontWeight: 'bold' } : {}}>
    <h3>{renderTitle(title)}</h3>
    <span>{children}</span>
  </PaymentDetailDiv>
);
