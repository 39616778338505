class Downloader implements Downloader {

  protected readonly blob: Blob;
  protected readonly fileName: string;
  protected link: HTMLAnchorElement | undefined;

  constructor(blob: Blob, fileName: string) {
    this.blob = blob;
    this.fileName = fileName;
  }

  public startDownload() {
    this.createAnchorElement();
    if (this.link) {
      this.link.dispatchEvent(new MouseEvent('click'));
    }
  }

  protected getObjectUrl() {
    return URL.createObjectURL(this.blob);
  }

  protected createAnchorElement() {
    const link = <HTMLAnchorElement>document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    link.href = this.getObjectUrl();
    link.download = this.fileName;
    this.link = link;
  }
}

export const initializeBrowserDownloadDialog = (blob: Blob, fileName: string) => new Downloader(blob, fileName);
