import * as deepEqual from 'deep-equal';
import * as React from 'react';
import { TemplateInvoice, TemplateInvoiceCurrencyDetails } from '../common';
import { CurrencyDetailsView } from './CurrencyDetailsView';

const get = require('lodash/get');

type Props = {
  invoice: TemplateInvoice;
  defaultCurrencyCode: string;
  formatDate: (date: Date) => string;
  langs?: string[];
};

export const ExchangeRates: React.FC<Props> = ({ invoice, defaultCurrencyCode, formatDate, langs }) => {
  const currencyDetails = invoice.currencyDetails;
  const originalCurrencyDetails: TemplateInvoiceCurrencyDetails | undefined =
    get(invoice, 'correctionData.originalData.currencyDetails');

  if (!currencyDetails) {
    return null;
  }

  if (invoice.correction
    && currencyDetails
    && originalCurrencyDetails
    && !deepEqual(currencyDetails.rate, originalCurrencyDetails.rate)) {
    return (
      <>
        <b>Kurs przed korektą:</b>
        <CurrencyDetailsView
          currencyDetails={originalCurrencyDetails}
          defaultCurrencyCode={defaultCurrencyCode}
          formatDate={formatDate}
        />
        <b>Kurs po korekcie:</b>
        <CurrencyDetailsView
          currencyDetails={currencyDetails}
          defaultCurrencyCode={defaultCurrencyCode}
          formatDate={formatDate}
        />
      </>
    );
  }

  return (
    <CurrencyDetailsView
      currencyDetails={currencyDetails}
      defaultCurrencyCode={defaultCurrencyCode}
      formatDate={formatDate}
      langs={langs}
    />
  );
};
