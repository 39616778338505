export const getEntryFieldIndex = (fieldName?: string): number | undefined => {
  if (!fieldName) {
    return;
  }

  const entriesIndexRegexp = new RegExp('[e|E]ntries\\[([0-9].*)].*');
  const regExpExecArray = entriesIndexRegexp.exec(fieldName);

  if (!regExpExecArray || regExpExecArray.length < 1) {
    return;
  }

  const [_, index] = regExpExecArray;

  return parseInt(index, 10);
};
