import { DateWithLabel, Label } from '../../../../components/DocumentTemplate-styles';
import * as React from 'react';
import styled from 'styled-components';
import { TemplateInvoice } from '../../../common';

const CorrectedInvoiceNumber = styled.span`
  overflow-wrap: break-word;
`;

type Props = {
  invoice: TemplateInvoice;
  formatDate(value: Date): string;
};
export const CorrectionHeader = (props: Props) => {
  const { invoice, formatDate } = props;

  if (!invoice.correction || !invoice.correctionData) {
    return null;
  }

  return (
    <>
      <br/>
      <DateWithLabel>
        <Label>Faktura korygowana nr</Label>&nbsp;
        <CorrectedInvoiceNumber>{invoice.correctionData.number}</CorrectedInvoiceNumber>
      </DateWithLabel>
      <DateWithLabel>
        <Label>wystawiona dnia: </Label>&nbsp;
        <span>{formatDate(invoice.correctionData.issuedDate)}</span>
      </DateWithLabel>
    </>
  );
};
