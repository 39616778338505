import { PrintCurrency } from '../../../../components/PrintCurrency';
import { EntryNameTd } from '../../../../components/Table/EntryName';
import { EntryNumberTd } from '../../../../components/Table/EntryNumber';
import { EntryQuantityTd } from '../../../../components/Table/EntryQuantity';
import { EntryVatTd } from '../../../../components/Table/EntryVat';
import * as React from 'react';
import styled from 'styled-components';
import { getTotalVat } from '../../../../../../helpers/getTotalVat';
import { TemplateInvoice, TemplateInvoiceEntry } from '../../../common';

const Big = require('big.js');
const get = require('lodash/get');

type CorrectionEntryRowProps = {
  entry: TemplateInvoiceEntry;
  index: number;
  invoice: TemplateInvoice;
  hideVAT?: boolean;
};

const CorrectionTd = styled.td`
  text-align: left;
  padding-bottom: 0.2rem !important;
  color: #949494;
  font-weight: bold;
`;

export const CorrectionEntryRow = (props: CorrectionEntryRowProps) => {
  const { entry, index, invoice } = props;
  const correctedChangeRate = get(invoice, 'currencyDetails.rate.mid', null);
  const originalChangeRate = get(invoice, 'correctionData.originalData.currencyDetails.rate.mid', null);

  const correctionData = invoice.correctionData;

  if (!correctionData
    || !correctionData.originalData
    || !correctionData.correctedData) {
    return null;
  }
  const originalEntry = correctionData.originalData.entries[index];
  const correctedEntry = correctionData.correctedData.entries[index];

  const totalVatOriginal = getTotalVat({
    changeRate: originalChangeRate,
    totalNet: originalEntry.totalNet,
    totalGross: originalEntry.totalGross,
  });
  const totalVatCorrected = getTotalVat({
    changeRate: correctedChangeRate,
    totalNet: correctedEntry.totalNet,
    totalGross: correctedEntry.totalGross,
  });

  const totalVatCorrection = Number(Big(totalVatCorrected).minus(totalVatOriginal));

  return (
    <>
      <tr>
        <EntryNumberTd className="without-border" rowSpan={2}>{index + 1}</EntryNumberTd>
        <CorrectionTd className="without-border">przed korektą:</CorrectionTd>
        <EntryQuantityTd
          className="without-border"
          rowSpan={2}
        >
          {originalEntry.qty} {originalEntry.unit}
        </EntryQuantityTd>
        <td className="without-border" rowSpan={2}><PrintCurrency numericValue={originalEntry.net}/></td>
        <td className="without-border" rowSpan={2}><PrintCurrency numericValue={originalEntry.totalNet}/></td>
        {!props.hideVAT && (
          <>
            <EntryVatTd className="without-border" rowSpan={2}>{originalEntry.VAT.label}</EntryVatTd>
            <td className="without-border" rowSpan={2}><PrintCurrency numericValue={totalVatOriginal}/></td>
            <td className="without-border" rowSpan={2}><PrintCurrency numericValue={originalEntry.totalGross}/></td>
          </>
        )}
      </tr>
      <tr>
        <EntryNameTd className="without-border">
          {originalEntry.name}
        </EntryNameTd>
      </tr>
      <tr>
        <EntryNumberTd className="without-border" rowSpan={2}/>
        <CorrectionTd className="without-border">po korekcie:</CorrectionTd>
        <EntryQuantityTd className="without-border" rowSpan={2} >
          {correctedEntry.qty} {correctedEntry.unit}
        </EntryQuantityTd>
        <td className="without-border" rowSpan={2}><PrintCurrency numericValue={correctedEntry.net}/></td>
        <td className="without-border" rowSpan={2}><PrintCurrency numericValue={correctedEntry.totalNet}/></td>
        {!props.hideVAT && (
          <>
            <EntryVatTd className="without-border" rowSpan={2}>{correctedEntry.VAT.label}</EntryVatTd>
            <td className="without-border" rowSpan={2}><PrintCurrency numericValue={totalVatCorrected}/></td>
            <td className="without-border" rowSpan={2}><PrintCurrency numericValue={correctedEntry.totalGross}/></td>
          </>
        )}
      </tr>
      <tr>
        <EntryNameTd className="without-border">
          {entry.name !== originalEntry.name ? correctedEntry.name : ''}
        </EntryNameTd>
      </tr>
      <tr>
        <EntryNumberTd rowSpan={2}/>
        <CorrectionTd className="without-border">korekta:</CorrectionTd>
        <EntryQuantityTd rowSpan={2}>{entry.qty} {entry.unit}</EntryQuantityTd>
        <td rowSpan={2}><PrintCurrency numericValue={entry.net}/></td>
        <td rowSpan={2}><PrintCurrency numericValue={entry.totalNet}/></td>
        {!props.hideVAT && (
          <>
            <EntryVatTd rowSpan={2}>{entry.VAT.label}</EntryVatTd>
            <td rowSpan={2}><PrintCurrency numericValue={totalVatCorrection}/></td>
            <td rowSpan={2}><PrintCurrency numericValue={entry.totalGross}/></td>
          </>
        )}
      </tr>
      <tr>
        <EntryNameTd>
          {entry.name}
        </EntryNameTd>
      </tr>
    </>
  );
};
