import * as React from 'react';
import { Button } from '../../../DesignSystemV2/Elements/Buttons/Button/Button';

const FileDownloadIcon = require('components/src/components/Icons/file-download.svg').default;

export const DownloadPdfButton: React.FC<React.ComponentProps<typeof Button>> = (props) => (
  <Button
    IconComponent={FileDownloadIcon}
    style={{
      margin: '2rem',
      marginLeft: 'auto',
    }}
    {...props}
  >
    Pobierz pdf
  </Button>
);
