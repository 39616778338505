import {
  bottomPadding,
  fontSize,
  horizontalPadding,
  printLeftPadding, topPadding,
} from './../../DocumentTemplates/components/DocumentTemplate-styles';

export const styles = { fontSize, horizontalPadding, printLeftPadding, topPadding, bottomPadding };

export type TemplateInvoiceSideInfo = {
  ref?: {
    id: string;
    collection: TemplateCollections.COMPANIES | TemplateCollections.CLIENTS;
  },
  basicData: TemplateCompanyBasicData;
};

export type TemplateEntriesWithSums = {
  entries: TemplateInvoiceEntry[];
  summary: TemplateVatSummaryWithSums;
};

export type TemplateCorrectionOriginalData = TemplateEntriesWithSums & {
  currencyDetails?: TemplateInvoiceCurrencyDetails;
};

export type TemplateCorrectionData = {
  id: string;
  number: string;
  reason: string;
  sellDate: Date;
  issuedDate: Date;
  originalData: TemplateCorrectionOriginalData;
  correctedData: TemplateEntriesWithSums;
};

export type TemplateInvoice = {
  id: string;
  pictureUrl: string;
  number: string;
  type: TemplateInvoiceType;

  issuedAt: Date;
  paymentDate: Date;
  sellDate?: Date;

  buyer: TemplateInvoiceSideInfo;
  seller: TemplateInvoiceSideInfo;
  paymentDataEntry?: TemplatePaymentData;

  issuer: string;
  receiver: string;
  issuerId?: string;

  entries: TemplateInvoiceEntry[];
  summary: TemplateVatSummaryWithSums;

  toPay?: number;
  paymentMethodDisplayName: string;
  payment?: number;
  splitPayment?: boolean;
  comments?: string;
  canceled?: boolean;
  currencyDetails?: TemplateInvoiceCurrencyDetails;
  correction?: boolean;
  correctionData?: TemplateCorrectionData;
  prepayment?: boolean;
  prepaymentData?: TemplatePrepaymentInvoiceData;

  marginSchemeLabel?: string;
  cashAccounting?: boolean;
  taxExemptionLegalBasis?: string;
};

export type TemplatePublicInvoice = {
  number: string;
  issuedAt: Date;
  paymentDate: Date;
  sellDate?: Date;

  buyer: string;
  seller: string;
  paymentData: string;

  issuer: string;
  receiver: string;

  entries: TemplateInvoiceEntry[];
  summary: TemplateVatSummaryWithSums;

  toPay?: number;
  paymentMethodDisplayName: string;
  payment?: number;
  splitPayment?: boolean;
  comments?: string;

  cashAccounting?: boolean;
  taxExemptionLegalBasis?: string;
};

export type TemplateVATSummary = {
  VAT: string,
  net: number;
  vatValue: number;
  gross: number;
};

export type TemplateVatSummaryWithSums = {
  VAT: TemplateVATSummary[],
  sumGross: number,
  sumNet: number,
  sumVAT: number,
};

export type TemplateInvoicePrepaymentFinalSummary = TemplateVatSummaryWithSums;

export type TemplatePrepaymentInvoicesListRow = {
  id: string;
  number: string;
  issuedAt: Date;
  summary: TemplateVatSummaryWithSums;
};

export type TemplatePrepaymentInvoicesList = TemplatePrepaymentInvoicesListRow[];

export type TemplatePrepaymentInvoicesListSummary = {
  sumGross: number;
  sumNet: number;
  sumVAT: number;
};

export interface TemplatePrepaymentInvoiceData {
  previousPrepaymentInvoiceId?: string;
  final?: boolean;
  prepaymentInvoicesList?: TemplatePrepaymentInvoicesList;
  prepaymentInvoicesListSummary?: TemplatePrepaymentInvoicesListSummary;
  orderEntries?: TemplateInvoiceEntry[];
  orderSummary?: TemplateInvoicePrepaymentFinalSummary;
  finalSummary?: TemplateVatSummaryWithSums;
}

export interface RenderInvoiceConfig {
  defaultCurrencyCode: string;
}

export enum TemplateCollections {
  COMPANIES = 'companies',
  CLIENTS = 'clients',
}

export interface TemplateInvoiceEntry {
  name: string;
  net: number;
  qty: number;
  unit: string;
  VAT: {
    label: string;
    value: number;
  };
  totalNet: number;
  totalGross: number;
}

export interface TemplateInvoiceCurrencyDetailsRate {
  mid: number;
  no: string;
  effectiveDate: Date;
}

export interface TemplateInvoiceCurrencyDetails {
  code?: string;
  currency?: string;
  source?: string;
  rate?: TemplateInvoiceCurrencyDetailsRate;
}

export interface TemplateCompanyBasicData {
  name?: string;
  NIP?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  countryCodeISO_3166_1_alpha_2?: string;
  phoneNumber?: string;
  email?: string;
  houseNumber?: string;
  building?: string;
  VATINCountryCode?: string;
}

export interface TemplatePaymentData {
  accountNumber?: string;
  bankName?: string;
}

export enum TemplateInvoiceType {
  SALES = 'sales',
  RECEIPT = 'receipt',
  VAT_MARGIN = 'vat_margin',
  SALES_REVERSE_CHARGE = 'sales_reverse_charge',
  SALES_ABROAD_SERVICES = 'sales_abroad_services',
  PROFORMA = 'proforma',
}
