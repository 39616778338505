const unitiesWords = [
  '', ' jeden', ' dwa', ' trzy', ' cztery', ' pięć', ' sześć', ' siedem', ' osiem', ' dziewięć'];
const teensWords = ['', ' jedenaście', ' dwanaście', ' trzynaście', ' czternaście', ' piętnaście', ' szesnaście',
  ' siedemnaście', ' osiemnaście', ' dziewiętnaście'];
const tensWords = ['', ' dziesieć', ' dwadzieścia', ' trzydzieści', ' czterdzieści', ' pięćdziesiąt',
  ' sześćdziesiąt', ' siedemdziesiąt', ' osiemdziesiąt', ' dziewięćdziesiąt'];
const hundredsWords = ['', ' sto', ' dwieście', ' trzysta', ' czterysta', ' pięćset', ' sześćset', ' siedemset'
  , ' osiemset', ' dziewięćset'];
const groupsWords = [
  ['', '', ''],
  [' tysiąc', ' tysiące', ' tysięcy'],
  [' milion', ' miliony', ' milionów'],
  [' miliard', ' miliardy', ' miliardów'],
  [' bilion', ' biliony', ' bilionów'],
  [' biliard', ' biliardy', ' biliardów'],
  [' trylion', ' tryliony', ' trylionów']];
export const integerToWords = (originalNum: number): string => {

  let num = originalNum;
  if (Math.floor(num) === 0) {
    return 'zero';
  }

  let result = '';
  let sign = '';

  if (num < 0) {
    sign = 'minus';
    num = -num;
  }

  const absNum = Math.abs(num);

  if (Math.floor(absNum) === 0) {
    return 'zero';
  }

  let groups = 0;

  while (num > 0) {
    const hundreds = Math.floor((num % 1000) / 100);
    let teens = 0;
    let tens = Math.floor((num % 100) / 10);
    let unities = Math.floor(num % 10);

    if (tens === 1 && unities > 0) {
      teens = unities;
      tens = unities = 0;
    }

    let groupVariety = 2;
    if (unities === 1 && hundreds + tens + teens === 0) {
      groupVariety = 0;
    }
    if (unities === 2 || unities === 3 || unities === 4) {
      groupVariety = 1;
    }

    if (hundreds + tens + teens + unities > 0) {
      result = hundredsWords[hundreds]
        + tensWords[tens]
        + teensWords[teens]
        + unitiesWords[unities]
        + groupsWords[groups][groupVariety]
        + result;
    }

    groups = groups + 1;
    num = Math.floor(num / 1000);
  }

  return (sign + result).trim();
};
