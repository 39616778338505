import * as React from 'react';
import { getTotalVat } from '../../../../helpers/getTotalVat';
import { isEmpty } from '../../../../helpers/isEmpty';
import { DocumentSummary } from '../../components/DocumentSummary';
import {
  Col,
  Container,
  ContractSide,
  ContractSides,
  DateWithLabel,
  DocumentNumber,
  EntryGrossTh,
  EntryNetTh,
  EntryVatTotalTh,
  Header,
  Heading1,
  IssuerIdentity,
  IssuerSignature,
  Label,
  NameSurname,
  Payment,
  SubHeader,
  Table,
} from '../../components/DocumentTemplate-styles';
import { Logo } from '../../components/Logo';
import { PaymentData } from '../../components/PaymentData';
import { PaymentDetail } from '../../components/PaymentDetail';
import { PrintCurrency } from '../../components/PrintCurrency';
import { Side } from '../../components/Side';
import { EntryNameTh } from '../../components/Table/EntryName';
import { EntryNumberTh } from '../../components/Table/EntryNumber';
import { EntryQuantityTh } from '../../components/Table/EntryQuantity';
import { EntryRow } from '../../components/Table/EntryRow';
import { EntryVatTh } from '../../components/Table/EntryVat';
import { Summary } from '../../components/Table/Summary';
import { TotalToPayDetail } from '../../components/TotalToPayDetail';
import { TemplateInvoice, TemplateInvoiceSideInfo, TemplateInvoiceType } from '../common';
import { CorrectionEntryRow } from './components/correction/CorrectionEntryRow';
import { CorrectionHeader } from './components/correction/CorrectionHeader';
import { CorrectionSummary } from './components/correction/CorrectionSummary';
import { PrepaymentInvoices } from './components/prepayment/PrepaymentInvoices';
import { SPLIT_PAYMENT_LABEL } from './consts';
import { ExchangeRates } from './ExchangeRates';
import { getInvoiceTemplateTitle } from './helpers/getInvoiceTemplateTitle';
import { getLabelFor } from './helpers/getLabelFor';
import { isReceiptLike } from './helpers/isReceiptLike';
import { InvoiceAnnotation } from './InvoiceAnnotation';
import { CancelInvoice } from './styles';

const get = require('lodash/get');

export const invoiceLabelsDictionary = {
  invoiceTitle: {
    pl: '',
    en: 'Invoice',
  },
  issueDate: {
    pl: <>Data&nbsp;wystawienia</>,
    en: 'issue Date',
  },
  saleDate: {
    pl: <>Data&nbsp;sprzedaży</>,
    en: 'issue Date',
  },
  duplicate: {
    pl: 'Duplikat z dnia',
    en: 'Duplicate from day',
  },
  cashAccounting: {
    pl: 'Metoda kasowa',
    en: 'Cash accounting',
  },
  seller: {
    pl: 'Sprzedawca',
    en: 'Seller',
  },
  buyer: {
    pl: 'Nabywca',
    en: 'Bill to',
  },
  transaction: {
    pl: 'Transakcja',
    en: 'Transaction',
  },
  entryNumberHeader: {
    pl: 'Lp',
    en: 'No',
  },
  goodsServicesNameHeader: {
    pl: <>Nazwa towaru&nbsp;/&nbsp;usługi</>,
    en: 'Description',
  },
  quantityHeader: {
    pl: 'Ilość',
    en: 'Qty',
  },
  unitNetPrice: {
    pl: 'Cena netto',
    en: 'Unit price',
  },
  unitNetPriceWithConnector: {
    pl: 'Cena netto w',
    en: 'Unit price in',
  },
  netValue: {
    pl: 'Wartość netto',
    en: 'Total Excl. Tax',
  },
  netValueWithConnector: {
    pl: 'Wartość netto w',
    en: 'Total Excl. Tax in',
  },
  vatValue: {
    pl: 'Wartość VAT',
    en: 'Tax',
  },
  vatValueWithConnector: {
    pl: 'Wartość VAT w',
    en: 'Tax in',
  },
  vatHeader: {
    pl: 'VAT %',
    en: 'Tax rate',
  },
  grossValue: {
    pl: 'Wartość brutto',
    en: 'Total Incl. Tax',
  },
  grossValueWithConnector: {
    pl: 'Wartość brutto w',
    en: 'Total Incl. Tax in',
  },
  exchangeRate: {
    pl: 'Kurs waluty',
    en: 'Rate of exchange',
  },
  exchangeReferenceRates: {
    pl: 'tabela kursów średnich NBP nr',
    en: 'NBP foreign exchange reference rates no',
  },
  dayOfRate: {
    pl: 'z dnia',
    en: 'as at',
  },
  convertedVatAmount: {
    pl: 'Przeliczona kwota VAT',
    en: 'Calculated VAT amount',
  },
  dueDate: {
    pl: 'Termin płatności',
    en: 'Due date',
  },
  bankAccountNumber: {
    pl: 'Nr rachunku bankowego',
    en: 'Bank account number',
  },
  paid: {
    pl: 'Zapłacono',
    en: 'Paid',
  },
  total: {
    pl: 'Razem',
    en: 'Total',
  },
  totalValue: {
    pl: 'Wartość ogółem',
    en: 'Total value',
  },
  including: {
    pl: 'W tym',
    en: 'Including',
  },
  totalToPay: {
    pl: 'Razem do zapłaty',
    en: 'Total to pay',
  },
  toPayInWords: {
    pl: 'Słownie',
    en: 'In words',
  },
  totalDue: {
    pl: 'Pozostało do zapłaty',
    en: 'Total Due',
  },
  paymentMethod: {
    pl: 'Metoda płatności',
    en: 'Payment Method',
  },
  legalBasisVatExemption: {
    pl: 'Podstawa prawna zwolnienia z podatku VAT',
    en: 'Basis for the VAT exemption',
  },
  comments: {
    pl: 'Komentarz',
    en: 'Comments',
  },
  splitPayment: {
    pl: SPLIT_PAYMENT_LABEL,
    en: 'Split payment',
  },
  sellersName: {
    pl: 'Imię i nazwisko wystawcy',
    en: 'Seller\'s signature',
  },
  buyersName: {
    pl: 'Imię i nazwisko odbiorcy',
    en: 'Buyer\'s signature',
  },
};

const UNIT_VALUE_PL = 'Wartość jedn.';
const VALUE_PL = 'Wartość';
const MARGIN_SCHEME_PL = 'Procedura marży';

const REVERSE_CHARGE_EN = 'Reverse charge';
const REVERSE_CHARGE_PL = 'Odwrotne obciążenie';
const CORRECTION_REASON_PL = 'Przyczyna korekty';
const FINAL_SETTLEMENT_OF_TAX_RATES_PL = 'Rozliczenie końcowe według stawek podatkowych';

const CONNECTOR_PL = 'w';
const VAT_VALUE_PL = 'Wartość VAT';
const VAT_HEADER_PL = 'VAT %';
const GROSS_VALUE_PL = 'Wartość brutto';

const defaultSideRenderer = (side: TemplateInvoiceSideInfo): React.ReactNode => (
  <Side
    id={side.ref && side.ref.id}
    basicData={side.basicData}
  />
);

export type InvoiceDocumentProps = {
  copyDate?: Date;
  logoDisabled?: boolean;
  invoice: TemplateInvoice;
  defaultCurrencyCode: string;

  formatDate(value: Date): string;

  sideRenderer?(side: TemplateInvoiceSideInfo): React.ReactNode;

  prepaymentInvoiceNumberRenderer?(invoice: TemplateInvoice): React.ReactNode;

  langs?: string[];
};

const getCorrectionReason = (invoice: TemplateInvoice): string =>
  (invoice.correction && invoice.correctionData && invoice.correctionData.reason)
    ? invoice.correctionData.reason
    : '';

export const getInvoiceHasForeignCurrencyCode = (invoice: TemplateInvoice, defaultCurrencyCode: string) => (
  invoice.currencyDetails
  && invoice.currencyDetails.code
  && defaultCurrencyCode !== invoice.currencyDetails.code
);

export const InvoiceDocument = (props: InvoiceDocumentProps): JSX.Element => {
  const { invoice, defaultCurrencyCode, copyDate, formatDate, logoDisabled, langs } = props;

  const sideRenderer = props.sideRenderer ? props.sideRenderer : defaultSideRenderer;
  const issuedAt = formatDate(invoice.issuedAt);
  const paymentDate = formatDate(invoice.paymentDate);
  const invoiceHasForeignCurrencyCode = getInvoiceHasForeignCurrencyCode(invoice, defaultCurrencyCode);

  const changeRate = get(invoice, 'currencyDetails.rate.mid', null);

  const isCorrection = invoice.correction;
  const isPrepayment = invoice.prepayment;
  const isPrepaymentFinal = invoice.prepayment
    && invoice.prepaymentData
    && invoice.prepaymentData.final;

  const invoiceIsReceiptLike = isReceiptLike(invoice.type);
  const unitNetPriceLabel =
    invoiceIsReceiptLike ? UNIT_VALUE_PL : getLabelFor({
      langs,
      labelName: 'unitNetPrice',
      dictionary: invoiceLabelsDictionary,
    });
  const netPriceLabel = invoiceIsReceiptLike ? VALUE_PL : getLabelFor({
    langs,
    labelName: 'netValue',
    dictionary: invoiceLabelsDictionary,
  });

  const unitNetPriceLabelWithConnector = invoiceIsReceiptLike
    ? UNIT_VALUE_PL : getLabelFor({
      langs,
      labelName: 'unitNetPriceWithConnector',
      dictionary: invoiceLabelsDictionary,
    });
  const netPriceLabelWithConnector =
    invoiceIsReceiptLike ? VALUE_PL : getLabelFor({
      langs,
      labelName: 'netValueWithConnector',
      dictionary: invoiceLabelsDictionary,
    });

  const correctionReason = getCorrectionReason(invoice);
  const hasCorrectionReason = correctionReason !== '';

  const currencyCode = (invoiceHasForeignCurrencyCode && invoice.currencyDetails && invoice.currencyDetails.code)
    ? invoice.currencyDetails.code
    : defaultCurrencyCode;

  return (
    <Container>
      {Boolean(invoice.canceled) && (
        <CancelInvoice/>
      )}
      <Header>
        <Col className="preventExpanding">
          <Heading1>
            <span>
              {getInvoiceTemplateTitle(invoice)}
              {getLabelFor({
                langs,
                labelName: 'invoiceTitle',
                dictionary: invoiceLabelsDictionary,
              })}
            </span>
            <br/>
            <DocumentNumber>{invoice.number}</DocumentNumber>
          </Heading1>
          {copyDate && (
            <DateWithLabel>
              <Label>
                {getLabelFor({ langs, labelName: 'duplicate', dictionary: invoiceLabelsDictionary })}
              </Label>&nbsp;<span>{formatDate(copyDate)}</span>
            </DateWithLabel>
          )}
          <DateWithLabel>
            <Label>
              {getLabelFor({ langs, labelName: 'issueDate', dictionary: invoiceLabelsDictionary })}
            </Label>&nbsp;<span>{issuedAt}</span>
          </DateWithLabel>
          {invoice.sellDate && (
            <DateWithLabel>
              <Label>
                {getLabelFor({ langs, labelName: 'saleDate', dictionary: invoiceLabelsDictionary })}
              </Label>&nbsp;<span>{formatDate(invoice.sellDate)}</span>
            </DateWithLabel>
          )}
          {isCorrection && (
            <CorrectionHeader invoice={invoice} formatDate={formatDate}/>
          )}
          {invoice.type === TemplateInvoiceType.SALES_REVERSE_CHARGE && (
            <InvoiceAnnotation>
              {REVERSE_CHARGE_PL}
            </InvoiceAnnotation>
          )}
          {invoice.cashAccounting && (
            <InvoiceAnnotation>
              {getLabelFor({ langs, labelName: 'cashAccounting', dictionary: invoiceLabelsDictionary })}
            </InvoiceAnnotation>
          )}
          {invoice.type === TemplateInvoiceType.SALES_ABROAD_SERVICES && (
            <InvoiceAnnotation>
              {REVERSE_CHARGE_PL} / {REVERSE_CHARGE_EN}
            </InvoiceAnnotation>
          )}
        </Col>
        <Col>
          {!logoDisabled && !isEmpty(invoice.pictureUrl) && <Logo imgSrc={invoice.pictureUrl}/>}
        </Col>
      </Header>
      <ContractSides>
        <Col>
          <ContractSide>
            <SubHeader>{getLabelFor({ langs, labelName: 'seller', dictionary: invoiceLabelsDictionary })}</SubHeader>
            {sideRenderer && sideRenderer(invoice.seller)}
          </ContractSide>
        </Col>
        <Col>
          <ContractSide>
            <SubHeader>{getLabelFor({ langs, labelName: 'buyer', dictionary: invoiceLabelsDictionary })}</SubHeader>
            {sideRenderer && sideRenderer(invoice.buyer)}
          </ContractSide>
        </Col>
      </ContractSides>
      <>
        <SubHeader>{getLabelFor({ langs, labelName: 'transaction', dictionary: invoiceLabelsDictionary })}</SubHeader>
        <Table>
          <table>
            <thead>
            <tr>
              <EntryNumberTh>
                {getLabelFor({ langs, labelName: 'entryNumberHeader', dictionary: invoiceLabelsDictionary })}
              </EntryNumberTh>
              <EntryNameTh>
                {getLabelFor({ langs, labelName: 'goodsServicesNameHeader', dictionary: invoiceLabelsDictionary })}
              </EntryNameTh>
              <EntryQuantityTh>
                {getLabelFor({ langs, labelName: 'quantityHeader', dictionary: invoiceLabelsDictionary })}
              </EntryQuantityTh>
              <EntryNetTh>
                {invoiceHasForeignCurrencyCode && invoice.currencyDetails
                  ? <>{unitNetPriceLabelWithConnector} {invoice.currencyDetails.code}</>
                  : unitNetPriceLabel}
              </EntryNetTh>
              <EntryNetTh>
                {invoiceHasForeignCurrencyCode && invoice.currencyDetails
                  ? <>{netPriceLabelWithConnector} {invoice.currencyDetails.code}</>
                  : netPriceLabel}
              </EntryNetTh>
              {!invoiceIsReceiptLike && (
                <>
                  <EntryVatTh>{getLabelFor({
                    langs,
                    labelName: 'vatHeader',
                    dictionary: invoiceLabelsDictionary,
                  })}</EntryVatTh>
                  <EntryVatTotalTh>
                    {invoiceHasForeignCurrencyCode
                      ? (
                        <>
                          {getLabelFor({
                            langs,
                            labelName: 'vatValueWithConnector',
                            dictionary: invoiceLabelsDictionary,
                          })} {defaultCurrencyCode}
                        </>
                      )
                      : getLabelFor({ langs, labelName: 'vatValue', dictionary: invoiceLabelsDictionary })}
                  </EntryVatTotalTh>
                  <EntryGrossTh>
                    {invoiceHasForeignCurrencyCode && invoice.currencyDetails
                      ? (
                        <>
                          {getLabelFor({
                            langs,
                            labelName: 'grossValueWithConnector',
                            dictionary: invoiceLabelsDictionary,
                          })} {invoice.currencyDetails.code}
                        </>
                      )
                      : getLabelFor({ langs, labelName: 'grossValue', dictionary: invoiceLabelsDictionary })}
                  </EntryGrossTh>
                </>
              )}
            </tr>
            </thead>
            <tbody>
            {isCorrection
            && !isPrepaymentFinal
            && (
              invoice.entries.map((entry, idx) =>
                <CorrectionEntryRow
                  key={idx}
                  entry={entry}
                  invoice={invoice}
                  index={idx}
                  hideVAT={invoiceIsReceiptLike}
                />,
              )
            )}
            {isCorrection
            && isPrepaymentFinal
            && invoice.prepaymentData
            && invoice.prepaymentData.orderEntries
            && (
              invoice.prepaymentData.orderEntries.map((entry, idx) =>
                <CorrectionEntryRow
                  key={idx}
                  entry={entry}
                  invoice={invoice}
                  index={idx}
                  hideVAT={invoiceIsReceiptLike}
                />,
              )
            )}
            {!isCorrection
            && (
              isPrepaymentFinal && invoice.prepaymentData && invoice.prepaymentData.orderEntries
                ? (
                  invoice.prepaymentData.orderEntries.map((entry, idx) =>
                    <EntryRow
                      key={idx}
                      entry={entry}
                      index={idx}
                      hideVAT={invoiceIsReceiptLike}
                      totalVat={getTotalVat({
                        changeRate: get(invoice, 'currencyDetails.rate.mid', null),
                        totalNet: entry.totalNet,
                        totalGross: entry.totalGross,
                      })}
                    />,
                  )
                )
                : (
                  invoice.entries.map((entry, idx) =>
                    <EntryRow
                      key={idx}
                      entry={entry}
                      index={idx}
                      hideVAT={invoiceIsReceiptLike}
                      totalVat={getTotalVat({
                        changeRate: get(invoice, 'currencyDetails.rate.mid', null),
                        totalNet: entry.totalNet,
                        totalGross: entry.totalGross,
                      })}
                    />,
                  )
                )
            )}
            </tbody>
            <tfoot>
            {isCorrection && (
              <CorrectionSummary
                invoice={invoice}
                changeRate={changeRate}
                hideVAT={invoiceIsReceiptLike}
              />
            )}
            {!isCorrection && (
              isPrepaymentFinal && invoice.prepaymentData && invoice.prepaymentData.orderSummary
                ? (
                  <Summary
                    summary={invoice.prepaymentData.orderSummary}
                    hideVat={invoiceIsReceiptLike}
                  />
                )
                : (
                  <Summary
                    summary={invoice.summary}
                    hideVat={invoiceIsReceiptLike}
                    langs={langs}
                  />
                )
            )}
            </tfoot>
          </table>
        </Table>
      </>
      {isPrepaymentFinal && invoice.prepaymentData && invoice.prepaymentData.finalSummary && (
        <>
          <SubHeader>{FINAL_SETTLEMENT_OF_TAX_RATES_PL}</SubHeader>
          <Table>
            <table>
              <thead>
              <tr>
                <EntryNumberTh colSpan={2}/>
                <EntryNameTh/>
                <EntryQuantityTh style={{ width: '13.7em' }}/>
                <EntryNetTh>
                  {invoiceHasForeignCurrencyCode && invoice.currencyDetails
                    ? `${netPriceLabel} ${CONNECTOR_PL} ${invoice.currencyDetails.code}`
                    : netPriceLabel}
                </EntryNetTh>
                {!invoiceIsReceiptLike && (
                  <>
                    <EntryVatTh>{VAT_HEADER_PL}</EntryVatTh>
                    <EntryVatTotalTh>
                      {invoiceHasForeignCurrencyCode
                        ? `${VAT_VALUE_PL} ${CONNECTOR_PL} ${defaultCurrencyCode}`
                        : `${VAT_VALUE_PL}`}
                    </EntryVatTotalTh>
                    <EntryGrossTh>
                      {invoiceHasForeignCurrencyCode && invoice.currencyDetails
                        ? `${GROSS_VALUE_PL} ${CONNECTOR_PL} ${invoice.currencyDetails.code}`
                        : `${GROSS_VALUE_PL}`}
                    </EntryGrossTh>
                  </>
                )}
              </tr>
              </thead>
              <tbody/>
              <tfoot>
              <Summary
                summary={invoice.prepaymentData.finalSummary}
                hideVat={invoiceIsReceiptLike}
              />
              </tfoot>
            </table>
          </Table>
        </>
      )}
      {isPrepayment && (
        <PrepaymentInvoices
          invoice={invoice}
          prepaymentInvoiceNumberRenderer={props.prepaymentInvoiceNumberRenderer}
          defaultCurrencyCode={defaultCurrencyCode}
          formatDate={formatDate}
        />
      )}
      <DocumentSummary>
        {invoiceHasForeignCurrencyCode && (
          <>
            <ExchangeRates
              invoice={invoice}
              defaultCurrencyCode={defaultCurrencyCode}
              formatDate={formatDate}
              langs={langs}
            />
            <PaymentDetail
              title={getLabelFor({
                langs,
                labelName: 'convertedVatAmount',
                dictionary: invoiceLabelsDictionary,
              })}
            >
              <PrintCurrency numericValue={invoice.summary.sumVAT}/>{' '}{defaultCurrencyCode}
            </PaymentDetail>
          </>
        )}
        <Payment>
          <PaymentDetail
            title={getLabelFor({
              langs,
              labelName: 'dueDate',
              dictionary: invoiceLabelsDictionary,
              breakLines: true,
            })}
          >
            {paymentDate}
          </PaymentDetail>
          {invoice.paymentDataEntry && (
            !isEmpty(invoice.paymentDataEntry.accountNumber)
            || !isEmpty(invoice.paymentDataEntry.bankName)
          ) && (
            <PaymentDetail
              title={getLabelFor({
                langs,
                labelName: 'bankAccountNumber',
                dictionary: invoiceLabelsDictionary,
              })}
            >
              <PaymentData paymentData={invoice.paymentDataEntry}/>
            </PaymentDetail>
          )}
          {invoice.splitPayment && (
            <PaymentDetail title="">
              <span style={{ fontWeight: 'bold' }}>
                {getLabelFor({
                  langs,
                  labelName: 'splitPayment',
                  dictionary: invoiceLabelsDictionary,
                })}
              </span>
            </PaymentDetail>
          )}
          <TotalToPayDetail
            sumGross={invoice.summary.sumGross}
            currencyCode={currencyCode}
            langs={langs}
          />
          {(invoice.payment && !isNaN(invoice.payment))
            ? (
              <PaymentDetail
                title={getLabelFor({
                  langs, labelName: 'paid', dictionary: invoiceLabelsDictionary, breakLines: true,
                })}
              >
                <PrintCurrency
                  numericValue={invoice.payment}
                />&nbsp;{currencyCode}
              </PaymentDetail>
            ) : null
          }
          <PaymentDetail title={getLabelFor({ langs, labelName: 'totalDue', dictionary: invoiceLabelsDictionary })}>
            <PrintCurrency
              numericValue={invoice.toPay || 0}
            />&nbsp;{currencyCode}
          </PaymentDetail>
          {invoice.paymentMethodDisplayName && invoice.paymentMethodDisplayName.length > 0 && (
            <PaymentDetail
              title={getLabelFor({
                langs,
                labelName: 'paymentMethod',
                dictionary: invoiceLabelsDictionary,
              })}
            >
              {invoice.paymentMethodDisplayName}
            </PaymentDetail>
          )}
          {invoice.taxExemptionLegalBasis && invoice.taxExemptionLegalBasis !== '' && (
            <PaymentDetail
              title={getLabelFor({
                langs,
                labelName: 'legalBasisVatExemption',
                dictionary: invoiceLabelsDictionary,
              })}
            >
              {invoice.taxExemptionLegalBasis}
            </PaymentDetail>
          )}
          {invoice.marginSchemeLabel && invoice.marginSchemeLabel !== '' && (
            <PaymentDetail title={MARGIN_SCHEME_PL}>
              {invoice.marginSchemeLabel}
            </PaymentDetail>
          )}
          {hasCorrectionReason && (
            <PaymentDetail title={CORRECTION_REASON_PL}>
              {correctionReason}
            </PaymentDetail>
          )}
        </Payment>
      </DocumentSummary>
      {Boolean(invoice.comments) && (
        <PaymentDetail
          title={getLabelFor({
            langs, labelName: 'comments', dictionary: invoiceLabelsDictionary, breakLines: true,
          })}
        >
          {invoice.comments}
        </PaymentDetail>
      )}
      <IssuerSignature>
        <Col>
          <IssuerIdentity>
            <Label>{getLabelFor({ langs, labelName: 'sellersName', dictionary: invoiceLabelsDictionary })}</Label><br/>
            <NameSurname>{invoice.issuer}</NameSurname>
          </IssuerIdentity>
        </Col>
        <Col>
          <IssuerIdentity>
            <Label>{getLabelFor({ langs, labelName: 'buyersName', dictionary: invoiceLabelsDictionary })}</Label><br/>
            <NameSurname>{invoice.receiver}</NameSurname>
          </IssuerIdentity>
        </Col>
      </IssuerSignature>
    </Container>
  );
};
