import { getFormValues } from 'redux-form';
import { put, select } from 'redux-saga/effects';
import { updateField } from '../../reduxForm/updateField';
import { UpdateSumWorkerParams } from './createUpdateDocumentSumsWorker';
import { isEntryGrossField, isEntryTotalGrossField, isEntryTotalNetField, isEntryVATField } from './helpers';

const get = require('lodash/get');
const CURRENCY_CODE_FIELD_NAME = 'currencyDetails.rate.mid';

type BasicSumWorkerParams = UpdateSumWorkerParams;

export const getBasicSumWorkerParams = (formName: string, originOfChange: string): BasicSumWorkerParams => {

  function* formValueUpdater(formName, formData, fieldName, newValue) {
    const oldValue = get(formData, fieldName);

    if (oldValue !== newValue) {
      yield put(updateField(formName, fieldName, newValue, originOfChange));
    }
  }

  const createEntryUpdater = (fieldName, formName) => function* updateEntry(index, newValue) {
    const formData = yield select(getFormValues(formName));
    yield formValueUpdater(formName, formData, `entries[${index}].${fieldName}`, newValue);
  };

  const crateFieldUpdater = (fieldName, formName) => function* updateField(newValue) {
    const formData = yield select(getFormValues(formName));
    yield formValueUpdater(formName, formData, fieldName, newValue);
  };

  function* getChangeRate() {
    const formData = yield select(getFormValues(formName));
    return get(formData, CURRENCY_CODE_FIELD_NAME);
  }

  function* getSumStrategy() {
    const formData = yield select(getFormValues(formName));
    return formData.sumStrategy;
  }

  function* getEntriesStrategy() {
    const formData = yield select(getFormValues(formName));
    return formData.entriesStrategy;
  }

  function* getEntries() {
    const formData = yield select(getFormValues(formName));
    return formData.entries;
  }

  const setNet = createEntryUpdater('net', formName);
  const setTotalNet = createEntryUpdater('totalNet', formName);
  const setGross = createEntryUpdater('gross', formName);
  const setTotalGross = createEntryUpdater('totalGross', formName);
  const setSummary = crateFieldUpdater('summary', formName);

  return {
    getChangeRate, getSumStrategy, getEntriesStrategy, getEntries,
    setNet, setTotalNet, setGross, setTotalGross, setSummary,
    isEntryGrossField, isEntryTotalGrossField, isEntryTotalNetField, isEntryVATField,
  };
};
