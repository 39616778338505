import * as React from 'react';
import styled from 'styled-components';

const rowPadding = '0.83em 1.41em';
const rowBorderBottom = '0.1em solid #eaf8f4';
export const topPadding = 10;
export const bottomPadding = 10;
export const horizontalPadding = 10;
export const printLeftPadding = 15;
export const fontSize = 3.18;
const pageWidth = 210 / fontSize;
const contentWidth = pageWidth - ((horizontalPadding + printLeftPadding) / fontSize);
const headerWidth = 30;

export const Table = styled.div`
  margin-bottom: 3.33em;
  font-size: 0.98em;
  table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    text-align: right;
    table-layout: fixed;
  }

  th {
    padding: ${rowPadding};
    font-weight: bold;
    color: #949494;
    border-bottom: ${rowBorderBottom};
    &.without-border {
      border-bottom: none;
    }
  }

  td {
    padding: ${rowPadding};
  }

  tbody {
    td {
      border-bottom: ${rowBorderBottom};
      &.without-border {
        border-bottom: none;
      }
    }
  }

  tfoot {
    page-break-inside: avoid;
    display: table-row-group;
    th {
      padding: ${rowPadding};
      background-color: #ecf7ff;
      color: #000;
    }
  }
`;

export const SubHeader = styled.h3`
  font-size: 1em;
  text-transform: uppercase;
  color: #949494;
  letter-spacing: 0.084em;
  font-weight: bold;
`;

export const Col = styled.div`
  flex: 1;

  &.preventExpanding {
    overflow: auto;
  }
`;

export const DateWithLabel = styled.p`
  margin: 0.42em 0;
`;

export const Label = styled.span`
  color: #949494;
  min-width: 8.17em;
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
`;

export const Payment = styled.div`
  width: ${contentWidth}em;

  h4 {
    margin-top: 0;
    width: ${headerWidth}em;
    display: inline-block;
    font-weight: normal;
    vertical-align: top;
  }
`;

export const IssuerIdentity = styled.p`
  margin-top: 0;
  padding-left: 2.1em;
  line-height: 1.5em;
`;

export const NameSurname = styled.span`
  font-weight: bold;
`;

export const IssuerSignature = styled.div`
  display: flex;
  margin-top: auto;
  padding-top: 3em;
  justify-self: flex-end;
`;

export const Header = styled.div`
  display: flex;
`;

export const ContractSides = styled.div`
  margin-bottom: 2.5em;
  display: flex;
`;

export const ContractSide = styled.div`
  font-style: normal;
  line-height: 1.41em;
  h4 {
      margin-bottom: 0;
  }
`;

export const LogoContainerDiv = styled.div`
  margin-left: auto;
  flex: 1;
`;

export const LogoInnerContainerDiv = styled.div`
  width: 17em;
  height: 8.17em;
  max-width: 17em;
  max-height: 8.17em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const LogoImg = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

export const DocumentNumber = styled.span`
  font-weight: bold;
  overflow-wrap: break-word;
`;

export const Heading1 = styled.h1`
  font-size: 2em;
  font-weight: normal;
  margin: 0;
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const CurrencyDetails = styled.div`
  line-height: 1.2em;
`;

export const EntryNetTh = styled.th`
  min-width: 8.5em;
`;

export const EntryVatTotalTh = styled.th`
  min-width: 8.5em;
`;

export const EntryGrossTh = styled.th`
  min-width: 8.5em;
`;
