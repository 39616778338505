import * as React from 'react';
import { newLingSign } from './NewLingSign';

const SEPARATOR = ' / ';

type Dictionary = Record<string, Record<string, string | React.ReactElement>>;

interface GetConcatenatedLanguagesLabelProps {
  labelName: string;
  langs?: string[];
  dictionary: Dictionary;
  breakLines?: boolean;
}

const getConcatenatedLanguagesLabel =
  ({ labelName, langs= ['pl'], dictionary, breakLines }: GetConcatenatedLanguagesLabelProps) => {
    const labels = langs.map(lang => {
      const languageVariant = dictionary[labelName][lang];

      if (typeof languageVariant === 'object' && languageVariant.props?.children) {
        return languageVariant.props.children;
      }

      return languageVariant;
    });

    if (breakLines) {
      return labels.join(`${SEPARATOR}${newLingSign}`);
    }
    return labels.join(SEPARATOR);
  };

type GetLabelForProps = {
  labelName: string;
  langs?: string[];
  dictionary: Dictionary;
  breakLines?: boolean;
};

export const getLabelFor = ({ labelName, langs= ['pl'], dictionary, breakLines }: GetLabelForProps): React.ReactElement => {
  if (langs.length === 1) {
    return <>{dictionary[labelName][langs[0]]}</>;
  }

  const outputLabel = getConcatenatedLanguagesLabel({ labelName, langs, dictionary, breakLines });

  return (
    <span style={{ whiteSpace: breakLines ? 'pre-line' : 'unset' }}>
      {outputLabel}
    </span>
  );
};
