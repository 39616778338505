import { inflectWord } from './inflectWord';
import { inflections } from './pl_PL/inflections';

export const inflectCents = (cents: number, currencyCode?: string) => {

  const context = 'nominative';

  if (!currencyCode) {
    return inflectWord(inflections.centPLN, context, cents);
  }

  const code = currencyCode;

  if (code) {

    if (code === 'CHF') {
      return inflectWord(inflections.centCHF, context, cents);
    }

    if (code === 'PLN') {
      return inflectWord(inflections.centPLN, context, cents);
    }

    if (code === 'GBP') {
      return inflectWord(inflections.centGBP, context, cents);
    }

    return inflectWord(inflections.centForeign, context, cents);
  }

};
