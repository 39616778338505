import * as React from 'react';
import { PrintCurrency } from '../PrintCurrency';
import { EntryNameTd } from './EntryName';
import { EntryNumberTd } from './EntryNumber';
import { EntryQuantityTd } from './EntryQuantity';
import { EntryVatTd } from './EntryVat';

type EntryRowProps = {
  entry: {
    name: string;
    net: number;
    qty: number;
    unit: string;
    VAT: {
      label: string;
      value: number;
    };
    totalNet: number;
    totalGross: number;
  };
  index: number;
  hideVAT?: boolean;
  totalVat: number;
};

export const EntryRow: React.FC<EntryRowProps> = ({ entry, index, totalVat, hideVAT }) => (
  <tr>
    <EntryNumberTd>{index + 1}</EntryNumberTd>
    <EntryNameTd>{entry.name}</EntryNameTd>
    <EntryQuantityTd>{entry.qty} {entry.unit}</EntryQuantityTd>
    <td><PrintCurrency numericValue={entry.net}/></td>
    <td><PrintCurrency numericValue={entry.totalNet}/></td>
    {!hideVAT && (
      <>
        <EntryVatTd>{entry.VAT.label}</EntryVatTd>
        <td><PrintCurrency numericValue={totalVat}/></td>
        <td><PrintCurrency numericValue={entry.totalGross}/></td>
      </>
    )}
  </tr>
);
