const Big = require('big.js');
import { DocumentFormEntry } from '../../../components/Elements/DocumentEntries/DocumentEntries';

const countingStrategiesEntriesStrategies = ['FROM_NET_TOTAL', 'FROM_NET_UNIT'] as const;
type CountingStrategiesEntriesStrategies = typeof countingStrategiesEntriesStrategies[number];

const countingStrategiesSumStrategies = ['SUM', 'KEEP_GROSS', 'KEEP_NET'] as const;
type CountingStrategiesSumStrategy = typeof countingStrategiesSumStrategies[number];

export function precisionRound(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export const round = (number) => precisionRound(number, 0);

export const createEntryValuesGetterBasingOnNet = (strategy: CountingStrategiesEntriesStrategies) =>
  (entry: DocumentFormEntry) => {
    const qty = entry.qty;
    const VAT = entry.VAT.value;
    const net = entry.net;

    const gross = round(net * ((VAT + 100) / 100));
    let totalNet;
    let totalGross;

    if (strategy === 'FROM_NET_UNIT') {
      totalNet = round(gross * (100 / (VAT + 100)) * qty);
      totalGross = round(gross * qty);
    }

    if (strategy === 'FROM_NET_TOTAL') {
      totalNet = round(net * qty);
      totalGross = round(totalNet * ((VAT + 100) / 100));
    }

    return {
      ...entry,
      gross,
      totalGross,
      totalNet,
    };
  };

export const createEntryValuesGetterBasingOnGross = (strategy: CountingStrategiesEntriesStrategies) =>
  (entry: DocumentFormEntry) => {
    const qty = entry.qty;
    const VAT = entry.VAT.value;
    const gross = entry.gross;

    const net =
      round(gross * (100 / (VAT + 100)));
    const totalNet = round(net * qty);
    let totalGross;

    if (strategy === 'FROM_NET_UNIT') {
      totalGross = round(gross * qty);
    }

    if (strategy === 'FROM_NET_TOTAL') {
      totalGross = round(totalNet * ((VAT + 100) / 100));
    }

    return {
      ...entry,
      net,
      totalNet,
      totalGross,
    };
  };

export const createEntryValuesGetterBasingOnTotalNet = (strategy: CountingStrategiesEntriesStrategies) =>
  (entry: DocumentFormEntry) => {
    const qty = entry.qty ?? 1;
    const VAT = entry.VAT.value;
    const totalNet = entry.totalNet;

    let totalGross;
    const net =
      qty === 0
        ? 0
        : round(totalNet / qty);
    const gross = round(net * ((VAT + 100) / 100));

    if (strategy === 'FROM_NET_UNIT') {
      totalGross =
        qty === 0
          ? 0
          : round(gross * qty);
    }

    if (strategy === 'FROM_NET_TOTAL') {
      totalGross =
        qty === 0
          ? 0
          : round(totalNet * ((VAT + 100) / 100));
    }

    return {
      ...entry,
      net,
      gross,
      totalGross,
    };
  };

export const createEntryValuesGetterBasingOnTotalGross = (entry: DocumentFormEntry) => {
  const qty = entry.qty;
  const VAT = entry.VAT.value;
  const totalGross = entry.totalGross;

  const gross =
    qty === 0
      ? 0
      : round(totalGross / qty);

  const totalNet =
    qty === 0
      ? 0
      : round(totalGross * (100 / (VAT + 100)));
  const net =
    qty === 0
      ? 0
      : round(totalNet / qty);

  return {
    ...entry,
    net,
    gross,
    totalNet,
  };
};

export type GetSumsEntry = {
  totalGross: number;
  totalNet: number;
  VAT: {
    value: number;
  };
};

type GetSumResults = { sumGross: number, sumNet: number, sumVAT: number };

export const createSumsGetter = (strategy?: CountingStrategiesSumStrategy, changeRate?: number) =>
  (entries: GetSumsEntry[]): GetSumResults => {
    const exchangeVat = (changeRate, { sumVAT, sumNet, sumGross }) => ({
      sumNet, sumGross,
      sumVAT: !changeRate ? round(sumVAT) : round(sumVAT * changeRate),
    });

    if (strategy === 'KEEP_GROSS') {
      const sums = entries.reduce((acc, entry) => {
        const totalGross = entry.totalGross;
        const totalNet = round(entry.totalGross * (100 / (entry.VAT.value + 100)));
        const vat = round(totalGross - totalNet);

        return {
          sumGross: round(acc.sumGross + totalGross),
          sumNet: round(acc.sumNet + totalNet),
          sumVAT: round(acc.sumVAT + vat),
        };
      }, { sumGross: 0, sumNet: 0, sumVAT: 0 });
      return exchangeVat(changeRate, sums);
    }
    if (strategy === 'KEEP_NET') {
      const sums = entries.reduce((acc, entry) => {
        const totalNet = entry.totalNet;
        const vat = round(totalNet * (entry.VAT.value / 100));
        const gross = round(totalNet + vat);

        return {
          sumGross: round(acc.sumGross + gross),
          sumNet: round(acc.sumNet + totalNet),
          sumVAT: round(acc.sumVAT + vat),
        };
      }, { sumGross: 0, sumNet: 0, sumVAT: 0 });

      return exchangeVat(changeRate, sums);
    }

    const sums = entries.reduce((acc, entry) => {
      return {
        sumGross: round(Number(Big(acc.sumGross).plus(entry.totalGross))),
        sumNet: round(Number(Big(acc.sumNet).plus(entry.totalNet))),
        sumVAT: round(Number(Big(acc.sumVAT).plus(round(Number(Big(entry.totalGross).minus(entry.totalNet)))))),
      };
    }, { sumGross: 0, sumNet: 0, sumVAT: 0 });

    return exchangeVat(changeRate, sums);
  };
