export const inflections = {
  centPLN: {
    singular: {
      nominative: 'grosz',
      genitive: 'grosza',
      dative: 'groszowi',
      accusative: 'grosz',
      instrumental: 'groszem',
      locative: 'groszu',
      vocative: 'groszu',
    },
    plural: {
      nominative: 'grosze',
      genitive: 'groszy',
      dative: 'groszom',
      accusative: 'grosze',
      instrumental: 'groszami',
      locative: 'groszach',
      vocative: 'grosze',
    },
  },
  centForeign: {
    singular: {
      nominative: 'cent',
      genitive: 'centa',
      dative: 'centowi',
      accusative: 'centa',
      instrumental: 'centem',
      locative: 'cencie',
      vocative: 'cencie',
    },
    plural: {
      nominative: 'centy',
      genitive: 'centów',
      dative: 'centom',
      accusative: 'centy',
      instrumental: 'centami',
      locative: 'centach',
      vocative: 'centy',
    },
  },
  centCHF: {
    singular: {
      nominative: 'centym',
      genitive: 'centyma',
      dative: 'centymowi',
      accusative: 'centyma',
      instrumental: 'centymem',
      locative: 'centymie',
      vocative: 'centymie',
    },
    plural: {
      nominative: 'centymy',
      genitive: 'centymów',
      dative: 'centymom',
      accusative: 'centymy',
      instrumental: 'centymami',
      locative: 'centymach',
      vocative: 'centymy',
    },
  },
  centGBP: {
    singular: {
      nominative: 'pens',
      genitive: 'pensa',
      dative: 'pensowi',
      accusative: 'pensa',
      instrumental: 'pensem',
      locative: 'pensie',
      vocative: 'pensie',
    },
    plural: {
      nominative: 'pensy',
      genitive: 'pensów',
      dative: 'pensom',
      accusative: 'pensy',
      instrumental: 'pensami',
      locative: 'pensach',
      vocative: 'pensy',
    },
  },
};
