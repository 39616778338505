import { SubHeader, Table } from '../../../../components/DocumentTemplate-styles';
import { PrintCurrency } from '../../../../components/PrintCurrency';
import { FooterSummaryTitle } from '../../../../components/Table/FooterSummaryTitle';
import * as React from 'react';
import { UnderlinedTr } from '../../../../components/Table/UnderlinedTr';
import { TemplateInvoice } from '../../../common';
import {
  InvoiceGrossSumTh,
  InvoiceNetSumTh,
  InvoiceNumberTd,
  InvoiceNumberTh,
  InvoiceVatSumTh,
  IssueDateTd,
  IssueDateTh,
  OrdinalNumberTd,
  OrdinalNumberTh,
} from '../../styles';

type PrepaymentInvoicesProps = {
  invoice: TemplateInvoice;
  defaultCurrencyCode: string;
  formatDate(value: Date): string;
  prepaymentInvoiceNumberRenderer?(invoice: { id: string; number: string; })
};

function defaultPrepaymentInvoiceNumberRenderer(prepaymentInvoice) {
  return prepaymentInvoice.number;
}

export const PrepaymentInvoices = (props: PrepaymentInvoicesProps) => {
  const { invoice, formatDate } = props;

  const prepaymentInvoiceNumberRenderer = props.prepaymentInvoiceNumberRenderer
    ? props.prepaymentInvoiceNumberRenderer
    : defaultPrepaymentInvoiceNumberRenderer;

  if (!invoice.prepayment
    || !invoice.prepaymentData
    || !invoice.prepaymentData.prepaymentInvoicesList
    || !invoice.prepaymentData.prepaymentInvoicesListSummary) {
    return null;
  }

  const prepaymentInvoicesList = invoice.prepaymentData.prepaymentInvoicesList;
  const summary = invoice.prepaymentData.prepaymentInvoicesListSummary;

  return (
    <>
      <SubHeader>Zestawienie faktur zaliczkowych</SubHeader>
      <Table>
        <table>
          <thead>
          <tr>
            <OrdinalNumberTh>
              Lp
            </OrdinalNumberTh>
            <InvoiceNumberTh>
              Nr faktury
            </InvoiceNumberTh>
            <IssueDateTh>
              Data&nbsp;wystawienia
            </IssueDateTh>
            <InvoiceNetSumTh>
              Wartość netto
            </InvoiceNetSumTh>
            <InvoiceVatSumTh>
              Wartość VAT
            </InvoiceVatSumTh>
            <InvoiceGrossSumTh>
              Wartość brutto
            </InvoiceGrossSumTh>
          </tr>
          </thead>
          <tbody>
          {prepaymentInvoicesList.map((prepaymentInvoice, idx) => (
            <tr key={prepaymentInvoice.id}>
              <OrdinalNumberTd>{idx + 1}</OrdinalNumberTd>
              <InvoiceNumberTd>{prepaymentInvoiceNumberRenderer(prepaymentInvoice)}</InvoiceNumberTd>
              <IssueDateTd>{formatDate(prepaymentInvoice.issuedAt)}</IssueDateTd>
              <td><PrintCurrency numericValue={prepaymentInvoice.summary.sumNet}/></td>
              <td><PrintCurrency numericValue={prepaymentInvoice.summary.sumVAT}/></td>
              <td><PrintCurrency numericValue={prepaymentInvoice.summary.sumGross}/></td>
            </tr>
          ))}
          </tbody>
          <tfoot>
          <UnderlinedTr>
            <td colSpan={2}/>
            <FooterSummaryTitle>Razem:</FooterSummaryTitle>
            <th>
              <PrintCurrency
                numericValue={summary.sumNet}
              />
            </th>
            <th>
              <PrintCurrency
                numericValue={summary.sumVAT}
              />
            </th>
            <th>
              <PrintCurrency
                numericValue={summary.sumGross}
              />
            </th>
          </UnderlinedTr>
          </tfoot>
        </table>
      </Table>
    </>
  );
};
