import * as React from 'react';
import { currencyToWords } from '../../../helpers/currencyToWords';
import { getLabelFor } from '../InvoiceTemplate/InvoiceDocument/helpers/getLabelFor';
import { invoiceLabelsDictionary } from '../InvoiceTemplate/InvoiceDocument/InvoiceDocument';
import { PaymentDetail } from './PaymentDetail';
import { PrintCurrency } from './PrintCurrency';

type TotalToPayDetailProps = {
  sumGross: number;
  currencyCode: string;
  langs?: string[];
};

export const TotalToPayDetail: React.FC<TotalToPayDetailProps> = ({ sumGross, currencyCode, langs= ['pl'] }) => (
  <>
    <PaymentDetail
      title={getLabelFor({ langs, labelName: 'totalToPay', dictionary: invoiceLabelsDictionary, breakLines: true })}
      bold
    >
      <PrintCurrency
        numericValue={sumGross}
      />&nbsp;
      {currencyCode}
    </PaymentDetail>
    <PaymentDetail
      title={getLabelFor({ langs, labelName: 'toPayInWords', dictionary: invoiceLabelsDictionary, breakLines: true })}
    >
      {currencyToWords(sumGross, currencyCode)}
    </PaymentDetail>
  </>
);
