const API_URL = process.env['GATSBY_API_URL'];
const USER_EVENTS_URL = `${API_URL}/invoices`;

export const makeDownloadPdfRequest = async (data: any) =>
  await fetch(`${USER_EVENTS_URL}/public/pdf`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
  });
