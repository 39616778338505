import { createFormDataProvider } from 'components/src/helpers/reduxForm/createFormDataProvider';
import * as moment from 'moment';
import * as React from 'react';
import { Provider } from 'react-redux';
import { PublicInvoiceDocument } from '../../DocumentTemplates/InvoiceTemplate/InvoiceDocument/PublicInvoiceDocument';
import { DimensionsProvider } from '../../Elements/DimensionsProvider/DimensionsProvider';
import { styled } from '../../Layout/ThemeProvider/ThemeProvider';
import { InvoiceForm } from './components/InvoiceForm/InvoiceForm';
import { formName } from './consts';
import { PublicInvoiceFormData } from './PublicInvoiceFormData';
import { store } from './state/store';

const A4_RATIO = 1.414;

const DocumentInnerContainer = styled.div`
  transform-origin: top left;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  
  font-size: 3.18mm;
  padding: 4rem;

  width: 84rem;
  min-height: 118.7rem;

  border-radius: .8rem;
  
  box-shadow: 0.1rem 0.1rem 1.1rem -0.2rem rgba(0, 0, 0, 0.2);
  margin-top: 4rem;

  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.desktop}) {
    margin-top: 0;
  }

`;

const InvoiceStatelessFormContainer = styled.div`
  width: 100%;
  
  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.desktop}) {
    width: 50%;
  }
`;

const DocumentContainer = styled.div`
  width: 100%;
    
  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.desktop}) {
    padding-left: 2rem;
    width: 50%;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  margin: 2rem;
  padding-top:4rem;
  
  @media (${props => props.theme.deviceBreakpoints.mobile}) {
    margin-left: 4rem;
    margin-top: 0;
  } 
 
  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    margin-left: 14rem;
  }
  
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    margin-left: 28rem;
    margin-right: 28rem;
  }
`;

const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');

function getToPayValue(invoice: PublicInvoiceFormData): number | undefined {
  if (invoice.payment && invoice.payment < 0) {
    return 0;
  }

  const effectivePayment = invoice.payment || 0;

  return invoice.summary.sumGross >= effectivePayment
    ? (invoice.summary.sumGross - effectivePayment)
    : (effectivePayment - Math.abs(invoice.summary.sumGross));
}


const StateProvider = createFormDataProvider<PublicInvoiceFormData>(formName);

type Props = {
  onClickSubmit(invoiceFormData: PublicInvoiceFormData);
  pending?: boolean;
};

export const PublicInvoicePage: React.FC<Props> = ({ onClickSubmit, pending }) => (
  <Provider store={store}>
    <StateProvider
      render={invoiceFormData => (
        <Container>
          <InvoiceStatelessFormContainer>
            <InvoiceForm
              invoiceFormData={invoiceFormData}
              onClickSubmit={onClickSubmit}
              pending={pending}
            />
          </InvoiceStatelessFormContainer>
          {invoiceFormData && Object.keys(invoiceFormData).length > 0 && (
            <DimensionsProvider>
              {({ dimensions: { width: parentWidth } }) => (
                <DocumentContainer>
                  <DimensionsProvider>
                    {({ dimensions: { width: originalWidth } }) => {
                      const ratio = (parentWidth || 0) > (originalWidth || 0)
                        ? 1
                        : 0.95 / ((originalWidth || 0) / (parentWidth || 0));

                      const minHeight = (originalWidth || 0) * A4_RATIO;

                      return (
                        <DocumentInnerContainer
                          style={{ minHeight, transform: `scale(${ratio})` }}
                        >
                          <PublicInvoiceDocument
                            invoice={{
                              ...invoiceFormData,
                              toPay: getToPayValue(invoiceFormData),
                            }}
                            defaultCurrencyCode="PLN"
                            formatDate={formatDate}
                          />
                        </DocumentInnerContainer>
                      );
                    }}
                  </DimensionsProvider>
                </DocumentContainer>
              )}
            </DimensionsProvider>
          )}
        </Container>
      )}
    />
  </Provider>
);
