import * as React from 'react';

const NumberFormat = require('react-number-format');

export const PrintCurrency: React.FC<{ numericValue?: number, suffix?: string }> = ({ numericValue, suffix }) =>
  <NumberFormat {...{
    value: numericValue ? (isNaN(numericValue) ? 0 : (numericValue / 100)) : numericValue,
    thousandSeparator: ' ',
    decimalSeparator: ',',
    displayType: 'text',
    fixedDecimalScale: true,
    decimalScale: 2,
    ...(suffix ? { suffix: ' ' + suffix } : {}),
  }}/>;
