import { EventEmitter } from 'events';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { FormState } from 'redux-form';
import { all, call, fork } from 'redux-saga/effects';
import { createSagaMiddleware } from '../../../../helpers/createSagaMiddleware';
import { formName } from '../consts';
import { rootReducer } from './rootReducer';
import { createInvoiceFormEntryChangeWatcher, createInvoiceFormRemoveEntryWatcher } from './sagas';

const eventEmitter = new EventEmitter();

const sagaMiddleware = createSagaMiddleware(eventEmitter);

const forkAutoRestarting = (fn) =>
  fork(function* () {
    while (true) {
      try {
        yield call(fn);
      } catch (e) {
        // tslint:disable-next-line:no-console
        yield call(console.error, e);
      }
    }
  });

type State = {
  form: {
    [formName: string]: FormState;
  };
};

export const store: Store<State> = createStore(rootReducer, {}, composeWithDevTools({
  name: 'PublicInvoiceFormStore',
  maxAge: 200,
})(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(function* rootSaga() {
  yield all([
    forkAutoRestarting(function* tagsSagas() {
      yield all([
        fork(createInvoiceFormEntryChangeWatcher(formName)),
        fork(createInvoiceFormRemoveEntryWatcher(formName)),
      ]);
    }),
  ]);
});
