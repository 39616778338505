import { call } from 'redux-saga/effects';
import {
  createEntryValuesGetterBasingOnGross,
  createEntryValuesGetterBasingOnNet,
  createEntryValuesGetterBasingOnTotalGross,
  createEntryValuesGetterBasingOnTotalNet,
  createSumsGetter,
} from './countingStrategies';
import { getEntriesVATSummary } from './getEntriesVATSummary';
import { getEntryFieldIndex } from './getEntryFieldIndex';

export type UpdateSumWorkerParams = {
  getChangeRate: () => IterableIterator<any> | number;
  getSumStrategy: () => IterableIterator<any>;
  getEntriesStrategy: () => IterableIterator<any>;
  getEntries: () => IterableIterator<any>;
  setNet: (index, newValue) => IterableIterator<any>;
  setTotalNet: (index, newValue) => IterableIterator<any>;
  setGross: (index, newValue) => IterableIterator<any>;
  setTotalGross: (index, newValue) => IterableIterator<any>;
  setSummary: (newValue) => IterableIterator<any>;
  isEntryGrossField: (field: string) => boolean;
  isEntryTotalNetField: (field: string) => boolean;
  isEntryTotalGrossField: (field: string) => boolean;
  isEntryVATField: (field: string) => boolean;
};

const createEntryUpdater = (setNet, setGross, setTotalNet, setTotalGross) =>
  (entry, index) => call(
    function* updateEntry(entry, index) {
      yield setNet(index, entry.net);
      yield setGross(index, entry.gross);
      yield setTotalNet(index, entry.totalNet);
      yield setTotalGross(index, entry.totalGross);
    }, entry, index);

export const createUpdateEntriesSumsWorker = (creatorParams: UpdateSumWorkerParams) =>
  function* (params: { changedField?: string, forceAll?: boolean; } = {}) {
    const { changedField, forceAll } = params;
    const {
      getChangeRate, getSumStrategy, getEntriesStrategy, getEntries,
      setNet, setTotalNet, setGross, setTotalGross, setSummary,
      isEntryGrossField, isEntryTotalNetField, isEntryTotalGrossField, isEntryVATField,
    } = creatorParams;

    const createNewEntryValuesGetter = (entriesStrategy, field: string | undefined) => {
      if (!field) {
        return createEntryValuesGetterBasingOnNet(entriesStrategy);
      }

      const changeFromEntryGrossField = isEntryGrossField(field);
      const changeFromEntryTotalNetField = isEntryTotalNetField(field);
      const changeFromEntryTotalGrossField = isEntryTotalGrossField(field);
      const changeFromVatField = isEntryVATField(field);

      if (changeFromVatField) {
        return createEntryValuesGetterBasingOnTotalNet(entriesStrategy);
      }
      if (changeFromEntryGrossField) {
        return createEntryValuesGetterBasingOnGross(entriesStrategy);
      }
      if (changeFromEntryTotalNetField) {
        return createEntryValuesGetterBasingOnTotalNet(entriesStrategy);
      }
      if (changeFromEntryTotalGrossField) {
        return createEntryValuesGetterBasingOnTotalGross;
      }

      return createEntryValuesGetterBasingOnNet(entriesStrategy);
    };

    let entries = yield getEntries();
    const entriesStrategy = yield call(getEntriesStrategy);
    const getNewEntryValues = createNewEntryValuesGetter(entriesStrategy, changedField);
    const entryUpdater = createEntryUpdater(setNet, setGross, setTotalNet, setTotalGross);

    if (entries) {
      const entryIdxToChange = getEntryFieldIndex(changedField);

      if (forceAll) {
        yield entries
          .map(getNewEntryValues)
          .map(entryUpdater);

        // 0 is a correct value and getEntryFieldIndex might return undefined.
      } else if (entryIdxToChange !== undefined) {
        yield entryUpdater(getNewEntryValues(entries[entryIdxToChange]), entryIdxToChange);
      }

      const changeRate = yield call(getChangeRate);

      entries = yield getEntries();

      const sumStrategy = yield call(getSumStrategy);

      const summary = getEntriesVATSummary(entries, entries => {
        const getSums = createSumsGetter(sumStrategy, changeRate);
        const { sumGross, sumNet, sumVAT } = getSums(entries);
        return { gross: sumGross, net: sumNet, vatValue: sumVAT };
      });

      yield call(setSummary, summary);
    }
  };
