import * as React from 'react';
import { SuspenseProps } from 'react';
import { reduxForm } from 'redux-form';
import { Spinner } from '../../../../Elements/Spinner/Spinner';
import { styled, keyframes } from '../../../../Layout/ThemeProvider/ThemeProvider';
import { formName } from '../../consts';
import { PublicInvoiceFormData } from '../../PublicInvoiceFormData';
import { DownloadPdfButton } from '../DownloadPdfButton';

const SectionOne = React.lazy(() => import('./SectionOne').then(exports => ({ default: exports.SectionOne })));
const SectionTwo = React.lazy(() => import('./SectionTwo').then(exports => ({ default: exports.SectionTwo })));
const SectionThree = React.lazy(() => import('./SectionThree').then(exports => ({ default: exports.SectionThree })));
const SectionSix = React.lazy(() => import('./SectionSix').then(exports => ({ default: exports.SectionSix })));
const SectionEight = React.lazy(() => import('./SectionEight').then(exports => ({ default: exports.SectionEight })));

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SsrSuspense: React.FC<SuspenseProps> = props => {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && (
        <React.Suspense {...props}/>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  z-index: 0;
  border-radius: .8rem;
  background: ${({ theme }) => theme.colors.white};
  max-width: 84rem;
  
  padding-top: .5rem;
  box-shadow: 0.1rem 0.1rem 1.1rem -0.2rem rgba(0, 0, 0, 0.2);
  min-height: 69.3rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const fadeInAnimation = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  
  animation-name: ${fadeInAnimation};
  animation-duration: 1s;
  animation-iteration-count: 1;
`;

type Props = {
  invoiceFormData: PublicInvoiceFormData;
  onClickSubmit(invoiceFormData: PublicInvoiceFormData);
  pending?: boolean;
};

export const InvoiceForm = reduxForm<PublicInvoiceFormData, Props>({
  form: formName,
  initialValues: {
    issuedAt: new Date(),
    paymentDate: new Date(),
    buyer: '',
    seller: '',
    issuer: '',
    receiver: '',
    paymentData: '',
    paymentMethodDisplayName: '',
    number: '0001/2020',
    entriesStrategy: 'FROM_NET_TOTAL',
    sumStrategy: 'SUM',
    summary: {
      VAT: [{
        VAT: '23', net: 0, gross: 0, vatValue: 0,
      }],
      sumGross: 0,
      sumNet: 0,
      sumVAT: 0,
    },
    entries: [
      {
        qty: 1,
        net: 0,
        VAT: { value: 23, label: '23%' },
        totalGross: 0,
        totalNet: 0,
        name: '',
        gross: 0,
        unit: 'szt',
      },
    ],
  },
})(({ invoiceFormData, onClickSubmit, pending }) => (
  <Container>
    <Form>
      <SsrSuspense
        fallback={<LoadingOverlay><Spinner size={24}/></LoadingOverlay>}
      >
        <InnerContainer>
          <SectionOne/>
          <SectionTwo/>
          <SectionThree/>
          <SectionSix/>
          <SectionEight
            invoiceFormData={invoiceFormData}
          />
        </InnerContainer>
      </SsrSuspense>
    </Form>
    <DownloadPdfButton
      loading={pending}
      onClick={() => {
        onClickSubmit(invoiceFormData);
      }}
    />
  </Container>
));
