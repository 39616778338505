import { change } from 'redux-form';

export const updateField = (form: string, field: string, value, origin = 'unknown') => {
  const formAction = change(form, field, value);
  return ({
    ...formAction,
    meta: {
      ...formAction.meta,
      origin,
    },
  });
};
