const flow = require('lodash/fp/flow');
const groupBy = require('lodash/fp/groupBy');
const map = require('lodash/fp/map');
const mapValues = require('lodash/fp/mapValues');
const reverse = require('lodash/fp/reverse');
const sortBy = require('lodash/fp/sortBy');
const sumBy = require('lodash/fp/sumBy');
const toPairs = require('lodash/fp/toPairs');
import { GetSumsEntry } from './countingStrategies';

type VatSummary = {
  VAT: string,
  net: number;
  vatValue: number;
  gross: number;
};

type VatSummaryWithSums = {
  VAT: VatSummary[],
  sumGross: number,
  sumNet: number,
  sumVAT: number,
};

type InvoiceVATValue = {
  label: string;
  value: number;
};

type InvoiceEntry = {
  name: string;
  net: number;
  gross: number;
  qty: number;
  unit: string;
  VAT: InvoiceVATValue;
  totalNet: number;
  totalGross: number;
};

type SumsEntry = { net: number; vatValue: number; gross: number };

type GetVatSummaryEntries = {
  totalGross: number;
  totalNet: number;
  VAT: {
    value: number;
  };
};

export const getEntriesVATSummary = (
  entries: GetVatSummaryEntries[],
  getSums: (entries: GetSumsEntry[]) => SumsEntry,
):
  VatSummaryWithSums => {
  const VAT = (flow(
    groupBy((entry: InvoiceEntry) => entry.VAT.label),
    mapValues((entries, key) => getSums(entries)),
    toPairs,
    map(([VATlabel, data]) => ({ VAT: VATlabel, ...data })),
    sortBy(item => parseInt(item.VAT, 10)),
    reverse,
  ) as any)(entries);

  return {
    VAT,
    sumGross: sumBy('gross', VAT),
    sumNet: sumBy('net', VAT),
    sumVAT: sumBy('vatValue', VAT),
  };
};

export const negateVATSummary = (vatSummaryWithSums: VatSummaryWithSums): VatSummaryWithSums => ({
  sumNet: -vatSummaryWithSums.sumNet,
  sumGross: -vatSummaryWithSums.sumGross,
  sumVAT: -vatSummaryWithSums.sumVAT,
  VAT: vatSummaryWithSums.VAT.map((vatSummary: VatSummary): VatSummary => ({
    ...vatSummary,
    gross: -vatSummary.gross,
    net: -vatSummary.net,
    vatValue: -vatSummary.vatValue,
  })),
});
