export const createIsEntryFieldWithName =
  (path?: string) =>
    (field: string, entryFieldName: string) =>
      !!field.match(new RegExp(`${path ? path : ''}\\[[0-9].*]\\.${entryFieldName}`));

export const isEntryFieldWithName = createIsEntryFieldWithName();
export const isEntryTotalGrossField = (field: string) => isEntryFieldWithName(field, 'totalGross');
export const isEntryGrossField = (field: string) => isEntryFieldWithName(field, 'gross');
export const isEntryTotalNetField = (field: string) => isEntryFieldWithName(field, 'totalNet');
export const isEntryVATField = (field: string) => isEntryFieldWithName(field, 'VAT');

export const getEntryFieldIndex = (fieldName: string): number => {
  const regex = /^\w+\[(\d+)]\.\w+$/;
  const result = regex.exec(fieldName);
  return result && result[1] ? parseInt(result[1], 10) : NaN;
};
