import { Inflections } from './Inflections';

type contextType = 'nominative' | 'genitive' | 'dative' | 'accusative' | 'instrumental' | 'locative' | 'vocative';

export const inflectWord = (wordObject: Inflections, context: contextType, number: number) => {
  const toGenitive = ['nominative', 'accusative', 'vocative'];
  const absolute = Math.abs(number);

  if (toGenitive.indexOf(context) > -1
    && absolute !== 1
    && (absolute % 10 <= 1 || absolute % 10 >= 5 || (absolute % 100 >= 11 && absolute % 100 <= 19))) {
    return wordObject.plural.genitive;
  }

  return wordObject[absolute === 1 ? 'singular' : 'plural'][context];
};
