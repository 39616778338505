import { TemplateInvoice, TemplateInvoiceType } from '../../common';

const invoiceTypeTitles = {
  [TemplateInvoiceType.RECEIPT]: {
    standard: 'Rachunek',
    prepayment: 'Rachunek zaliczka',
    final: 'Rachunek końcowy',
    correction: {
      standard: 'Rachunek korekta',
      prepayment: 'Rachunek korekta',
      final: 'Rachunek korekta',
    },

  },
  [TemplateInvoiceType.VAT_MARGIN]: {
    standard: 'Faktura VAT Marża',
    prepayment: 'Faktura VAT Marża zaliczka',
    final: 'Faktura VAT Marża końcowa',
    correction: {
      standard: 'Faktura VAT Marża korekta',
      prepayment: 'Faktura VAT Marża korekta',
      final: 'Faktura VAT Marża korekta',
    },
  },
};

const DEFAULT_TITLES = {
  standard: 'Faktura',
  prepayment: 'Faktura zaliczka',
  final: 'Faktura końcowa',
  correction: {
    standard: 'Faktura korekta',
    prepayment: 'Faktura korekta',
    final: 'Faktura korekta',
  },
};
const PROFORMA_TITLE = 'Proforma';

export const getInvoiceTitle =
  (type: string, correction: boolean, prepaymentFinal: boolean, prepayment: boolean) => {
    if (type === TemplateInvoiceType.PROFORMA) {
      return PROFORMA_TITLE;
    }
    let titles = invoiceTypeTitles[type] || DEFAULT_TITLES;

    if (correction) {
      titles = titles.correction;
    }
    if (prepaymentFinal) {
      return titles.final;
    }
    if (prepayment) {
      return titles.prepayment;
    }

    return titles.standard;
  };

export const getInvoiceTemplateTitle = (invoice: TemplateInvoice) => {
  const type = invoice.type;
  const correction = !!invoice.correction;
  const prepayment = !!invoice.prepayment;
  const prepaymentData = invoice.prepaymentData;
  const isPrepaymentFinal = !!(prepayment && prepaymentData && prepaymentData.final);

  return getInvoiceTitle(type, correction, isPrepaymentFinal, prepayment);
};

export const getPublicInvoiceTitle = () => DEFAULT_TITLES.standard;
