import * as React from 'react';
import styled from 'styled-components'; // tslint:disable-line

export const InvoiceNetSumTh = styled.th`
  min-width: 7em;
`;

export const ordinalNumberColumnStyle = `
  color: #949494;
  width: 2em;
  font-weight: bold;
`;

export const OrdinalNumberTd = styled.td`
  ${ordinalNumberColumnStyle}
`;

export const OrdinalNumberTh = styled.th`
  ${ordinalNumberColumnStyle}
`;

export const invoiceNumberColumnStyle = `
  text-align: left;
  padding-left: 0.9em;
  width: 15.5rem;
`;

export const InvoiceNumberTd = styled.td`
  ${invoiceNumberColumnStyle}
  overflow-wrap: break-word;
`;

export const InvoiceNumberTh = styled.th`
  ${invoiceNumberColumnStyle}
`;

export const dateColumnStyle = `
  width: 4.3em;
  padding: 0 !important;
`;

export const IssueDateTd = styled.td`
  ${dateColumnStyle}
`;

export const IssueDateTh = styled.th`
  ${dateColumnStyle}
`;

export const InvoiceGrossSumTh = styled.th`
  min-width: 7em;
`;

export const InvoiceVatSumTh = styled.th`
  min-width: 7em;
`;

const canceledSVG = `
<svg width='352' height='202' xmlns='http://www.w3.org/2000/svg'>
  <g>
    <line
      stroke='#ff0000'
      stroke-width='4'
      y2='64' x2='332'
      y1='64' x1='-8'
      transform='rotate(-20 162,64)'
    />
    <text
      font-weight='bold'
      font-family="'Roboto Light', Helvetica, Arial, sans-serif"
      font-size='33'
      fill='#ff0000' xml:space='preserve' text-anchor='start'
      y='110' x='66'
      transform='rotate(-20 175,102)'
    >ANULOWANO</text>
    <line
      stroke='#ff0000'
      stroke-width='4'
      y2='130' x2='353'
      y1='134' x1='13'
      transform='rotate(-20 187,134)'
    />
  </g>
</svg>
`;

export const CancelInvoice = styled.div`
  position: absolute;
  opacity: 0.5;
  z-index: 1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-position: top center;
  background-repeat: space;
  background-size: contain;
  background-image: url("data:image/svg+xml;utf8,${encodeURIComponent(canceledSVG)}");
`;
