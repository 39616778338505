import { inflectCents } from '../i18n/inflectCents';
import { integerToWords } from './integerToWords';

export const currencyToWords = (valueInCents: number, currencyCode: string): string => {
  const betweenMinusHundredAndZeroCents = -100 < valueInCents && valueInCents < 0;

  const sign = betweenMinusHundredAndZeroCents ? 'minus ' : '';
  const integerPart = sign + integerToWords(valueInCents / 100);
  const floatingPart = integerToWords(Math.abs(valueInCents % 100));

  return `${integerPart} ` +
    `${currencyCode} ` +
    `${floatingPart} ` +
    `${inflectCents(valueInCents % 100, currencyCode)}`;
};
