import { bindActionCreators } from 'redux';
import { clearFields, submit } from 'redux-form';
import { makeComponentCreator } from 'redux-render-prop';

const get = require('lodash/get');

export const createFormDataProvider = <FormData>(formName: string) => {
  const prepareState = (state): FormData => <FormData>get(state, `form.${formName}.values`, {});

  return makeComponentCreator({
    prepareState,
    prepareActions: (dispatch) => bindActionCreators({ clearFields, submit }, dispatch),
  })({
    mapActions: a => ({ ...a }),
    mapState: (s: FormData) => s,
  });
};
