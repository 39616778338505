import { FormAction } from 'redux-form';
import { call, takeEvery } from 'redux-saga/effects';
import { createUpdateEntriesSumsWorker } from '../../../../helpers/accounting/DocumentEntriesSums/createUpdateDocumentSumsWorker';
import { getBasicSumWorkerParams } from '../../../../helpers/accounting/DocumentEntriesSums/getBasicSumWorkerParams';
import { isEntryFieldWithName } from '../../../../helpers/accounting/DocumentEntriesSums/helpers';
import { REDUX_FORM_ARRAY_REMOVE_ACTION, REDUX_FORM_CHANGE_ACTION } from '../../../../helpers/reduxForm/actions';

const CHANGE_FIELD_ORIGIN = 'CHANGE_FIELD_ORIGIN';

export const createInvoiceFormEntryChangeWatcher = (formName: string) =>
  function* watchAddInvoiceFormChange() {
    yield takeEvery(REDUX_FORM_CHANGE_ACTION, function* (action: FormAction) {
      const form = action.meta.form;
      const origin = action.meta.origin;
      const field = action.meta.field;

      if (form === formName && origin !== CHANGE_FIELD_ORIGIN) {
        const entryFieldHasChanged = ['net', 'totalNet', 'gross', 'VAT', 'qty', 'totalGross']
          .some(fieldName => isEntryFieldWithName(field, fieldName));

        const shouldRecalculateAllEntries = 'entries' === field;

        if (entryFieldHasChanged || shouldRecalculateAllEntries) {
          const updateInvoiceSumsWorker = createUpdateEntriesSumsWorker(
            getBasicSumWorkerParams(formName, CHANGE_FIELD_ORIGIN),
          );

          yield call(updateInvoiceSumsWorker, { changedField: field, forceAll: shouldRecalculateAllEntries });
        }
      }
    });
  };

export const createInvoiceFormRemoveEntryWatcher = (formName: string) =>
  function* watchInvoiceFormRemoveEntry() {
    yield takeEvery(REDUX_FORM_ARRAY_REMOVE_ACTION, function* (action: FormAction) {
      const form = action.meta.form;
      if (form === formName) {

        const updateInvoiceSumsWorker = createUpdateEntriesSumsWorker(
          getBasicSumWorkerParams(formName, CHANGE_FIELD_ORIGIN),
        );

        yield call(updateInvoiceSumsWorker);
      }
    });
  };
