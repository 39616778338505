import * as React from 'react';
import { findDOMNode } from 'react-dom';

export type Dimensions = {
  width?: number;
  height?: number;
};
export type ChildrenRenderPropsParam = {
  dimensions: Dimensions;
  forceRefresh();
};
export type Props = {
  children: (params: ChildrenRenderPropsParam) => React.ReactNode;
  onChange?(dims: Dimensions);
};

export const dimensionProviderRefresh = 'dimension-provider-refresh';

export class DimensionsProvider extends React.Component<Props, Dimensions> {
  timeout: any;

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.doRefresh();
    window.addEventListener('resize', this.doRefresh);
    window.addEventListener(dimensionProviderRefresh, this.doRefresh);
    this.timeout = setTimeout(() => this.doRefresh(), 0);
  }

  componentWillUnmount() {
    this.doRefresh();
    window.removeEventListener('resize', this.doRefresh);
    window.removeEventListener(dimensionProviderRefresh, this.doRefresh);
    clearTimeout(this.timeout);
  }

  doRefresh = () => {
    if (!this) {
      return;
    }
    const $el = findDOMNode(this);

    if (!($el instanceof HTMLElement)) {
      return;
    }

    const dimensions = {
      width: $el.offsetWidth,
      height: $el.offsetHeight,
    };
    this.setState(dimensions);

    if (this.props.onChange) {
      this.props.onChange(dimensions);
    }
  };

  render() {
    return this.props.children({
      dimensions: this.state,
      forceRefresh: this.doRefresh,
    });
  }
}
