import styled from 'styled-components';

export const DocumentSummary = styled.div`
  :after {
    content: "";
    display: table;
    clear: both
  }
  margin-top: 2rem;

  page-break-inside: avoid;
`;
