import styled from 'styled-components';

export const EntryNameTd = styled.td`
  text-align: left;
  padding-left: 0.9em;
  overflow-wrap: break-word;
`;

export const EntryNameTh = styled.th`
  width: 15.5rem;
  text-align: left;
  padding-left: 0.9em;
`;
