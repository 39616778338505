type Params = {
  changeRate: number | null;
  totalNet: number;
  totalGross: number;
};

export const getTotalVat = ({ changeRate, totalGross, totalNet }: Params) =>
  (changeRate != null)
    ? (Math.round((totalGross - totalNet) * changeRate))
    : (totalGross - totalNet);
