import { PublicInvoicePage } from 'components/src/components/Forms/PublicInvoicePage/PublicInvoicePage';
import { ThemeProvider } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import { initializeBrowserDownloadDialog } from 'components/src/helpers/downloader';
import * as React from 'react';
import { makeDownloadPdfRequest } from '../api/makeDownloadPdfRequest';
import Header from '../components/Header/Header';
import { Image } from '../components/Image/Image';
import Seo from '../components/Seo/Seo';
import { WaveContainer } from '../components/WaveContainer/WaveContainer';
import { omit } from 'lodash';

const WystawFakturePage = () => {
  const [downloadingPdf, setDownloadingPdf] = React.useState(false);

  return (
    <ThemeProvider
      render={() => (
        <WaveContainer>
          <Header siteTitle="FAKTURUJ.TO proste!"/>
          <Seo title="FAKTURUJ.TO proste!"/>
          <PublicInvoicePage
            pending={downloadingPdf}
            onClickSubmit={(invoiceFormData) => {
              (async () => {
                setDownloadingPdf(true);
                try {
                  const response = await makeDownloadPdfRequest(
                    omit(invoiceFormData, ['sumStrategy', 'entriesStrategy']),
                  );

                  if (response.status === 200) {
                    initializeBrowserDownloadDialog(await response.blob(), 'faktura.pdf').startDownload();
                  } else {
                    setDownloadingPdf(false);
                    alert('Nie udało się pobrać faktury. Spróbuj później.');
                  }
                } catch (e) {
                  setDownloadingPdf(false);
                  alert('Nie udało się pobrać faktury. Spróbuj później.');
                } finally {
                  setDownloadingPdf(false);
                }
              })();
            }}
          />
          <Image/>
        </WaveContainer>
      )}
    />
  );
};

export default WystawFakturePage;
