export const isEmpty = (obj: string | object | any[] | undefined | null): boolean => {
  if (obj === null) {
    return true;
  }
  if (obj === undefined) {
    return true;
  }
  if (typeof obj === 'string') {
    return !obj.length;
  }

  return !Object.entries((obj || {})).length;
};
