import styled from 'styled-components';

const entryQuantity = `
  padding: 0 !important;
`;

export const EntryQuantityTd = styled.td`
  ${entryQuantity}
`;

export const EntryQuantityTh = styled.th`
  width: 4.3em;
  ${entryQuantity}
`;
