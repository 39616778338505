import { EventEmitter } from 'events';
import reduxSaga, { Task } from 'redux-saga';
import { AllEffect } from 'redux-saga/effects';

export const createSagaMiddleware = (eventEmitter: EventEmitter) => {
  const sagaMiddleware = reduxSaga();

  const watchersContainer = new WeakMap<() => IterableIterator<AllEffect>, Task>();

  eventEmitter.on('runSaga', (watchers: () => IterableIterator<AllEffect>) => {
    const saga = sagaMiddleware.run(watchers);
    watchersContainer.set(watchers, saga);
  });

  eventEmitter.on('cancelSaga', (watchers: () => IterableIterator<AllEffect>) => {
    if (watchersContainer.has(watchers)) {
      const saga = watchersContainer.get(watchers);
      saga!.cancel();
    }
  });

  return sagaMiddleware;
};
