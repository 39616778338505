import styled from 'styled-components';

const entryNumber = `
  width: 2em;
  color: #949494;
  font-weight: bold;
`;

export const EntryNumberTd = styled.td`
  color: #949494;
  font-weight: bold;
  vertical-align: top;
`;

export const EntryNumberTh = styled.th`
  ${entryNumber}
`;
