import * as React from 'react';
import { getLabelFor } from '../../InvoiceTemplate/InvoiceDocument/helpers/getLabelFor';
import { invoiceLabelsDictionary } from '../../InvoiceTemplate/InvoiceDocument/InvoiceDocument';
import { PrintCurrency } from '../PrintCurrency';
import { EntryVatTh } from './EntryVat';
import { FooterSummaryTitle } from './FooterSummaryTitle';
import { UnderlinedTr } from './UnderlinedTr';

type SummaryProps = {
  summary: {
    VAT: {
      VAT: string,
      net: number;
      vatValue: number;
      gross: number;
    }[],
    sumGross: number,
    sumNet: number,
    sumVAT: number,
  };
  hideVat?: boolean;
  langs?: string[];
};

export const Summary: React.FC<SummaryProps> = ({ summary, hideVat, langs= ['pl'] }) => (
  <>
    <UnderlinedTr>
      <td colSpan={3}/>
      <FooterSummaryTitle>{
        hideVat
          ? getLabelFor({ langs, labelName: 'totalValue', dictionary: invoiceLabelsDictionary })
          : getLabelFor({ langs, labelName: 'total', dictionary: invoiceLabelsDictionary, breakLines: true })
      }:</FooterSummaryTitle>
      <th>
        <PrintCurrency numericValue={summary.sumNet}/>
      </th>
      {!hideVat && (
        <>
          <th/>
          <th>
            <PrintCurrency numericValue={summary.sumVAT}/>
          </th>
          <th>
            <PrintCurrency numericValue={summary.sumGross}/>
          </th>
        </>
      )}
    </UnderlinedTr>
    {!hideVat && summary.VAT.map((vatSums, idx) => (
      <tr key={`${JSON.stringify(vatSums)}_summaryData`}>
        {idx === 0 ? (
          <>
            <td colSpan={3}/>
            <FooterSummaryTitle>{getLabelFor({
              langs,
              labelName: 'including',
              dictionary: invoiceLabelsDictionary,
            })}:</FooterSummaryTitle>
          </>
        ) : (
          <td colSpan={4}/>
        )}
        <th>
          <PrintCurrency numericValue={vatSums.net}/>
        </th>
        <EntryVatTh>
          {vatSums.VAT}
        </EntryVatTh>
        <th>
          <PrintCurrency numericValue={vatSums.vatValue}/>
        </th>
        <th>
          <PrintCurrency numericValue={vatSums.gross}/>
        </th>
      </tr>
    ))}
  </>
);
